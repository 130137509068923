import { createSlice } from '@reduxjs/toolkit';

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    usersList: [],
    invoiceObj: {},
    adminDashcampaignArr: [],
    selectedCampObj: {},
  },
  reducers: {
    setUsersList: (state, action) => {
      state.usersList = action.payload;
    },
    setInvoiceObj: (state, action) => {
      state.invoiceObj = action.payload;
    },
    setAdminDashCampaignArr: (state, action) => {
      state.adminDashcampaignArr = action.payload;
    },
    setSelectedCampObj: (state, action) => {
      state.selectedCampObj = action.payload;
    },
    resetAdmin: (state) => {
      state.usersList = [];
      state.invoiceObj = {};
      state.adminDashcampaignArr = [];
      state.selectedCampObj = {};
    },
  },
});

export const { setUsersList, setInvoiceObj, setAdminDashCampaignArr, setSelectedCampObj, resetAdmin } =
  adminSlice.actions;

export const selectAdmin = (state) => state.admin;

export default adminSlice.reducer;
