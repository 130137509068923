import { createSlice } from '@reduxjs/toolkit';

export const recruitSlice = createSlice({
  name: 'recruit',
  initialState: {
    type: '',
    companyName: '',
    campaignName: '',
    startingDate: new Date(),
    endingDate: new Date(),
    docUploadFile: null,
    docUploadUrl: '',
    brandPhilo: '',
    productToPromo: '',
    usp: '',
    ageRange: '',
    markSitua: '',
    objective: '',
    originalInfluArr: [],
    influArr: [],
    totalAmtObj: {
      storyAmt: 0,
      gridAmt: 0,
      igtvAmt: 0,
      total: 0,
    },

    originalInfluArrAddNewInflu: [],
    influArrAddNewInflu: [],
    totalAmtObjAddNewInflu: {
      storyAmt: 0,
      gridAmt: 0,
      igtvAmt: 0,
      total: 0,
    },

    formActiveStep: 0,
    formNewInfActiveStep: 0,
  },
  reducers: {
    setType: (state, action) => {
      state.type = action.payload;
    },
    setCompanyName: (state, action) => {
      state.companyName = action.payload;
    },
    setCampaignName: (state, action) => {
      state.campaignName = action.payload;
    },
    setStartingDate: (state, action) => {
      state.startingDate = action.payload;
    },
    setEndingDate: (state, action) => {
      state.endingDate = action.payload;
    },
    setDocUploadFile: (state, action) => {
      state.docUploadFile = action.payload;
    },
    setDocUploadUrl: (state, action) => {
      state.docUploadUrl = action.payload;
    },
    setBrandPhilo: (state, action) => {
      state.brandPhilo = action.payload;
    },
    setProductToPromo: (state, action) => {
      state.productToPromo = action.payload;
    },
    setUsp: (state, action) => {
      state.usp = action.payload;
    },
    setAgeRange: (state, action) => {
      state.ageRange = action.payload;
    },
    setMarkSitua: (state, action) => {
      state.markSitua = action.payload;
    },
    setObjective: (state, action) => {
      state.objective = action.payload;
    },
    setOriginalInfluArr: (state, action) => {
      state.originalInfluArr = action.payload;
    },
    setInfluArr: (state, action) => {
      state.influArr = action.payload;
    },
    setTotalAmtObj: (state, action) => {
      state.totalAmtObj = action.payload;
    },
    setOriginalInfluArrAddNewInflu: (state, action) => {
      state.originalInfluArrAddNewInflu = action.payload;
    },
    setInfluArrAddNewInflu: (state, action) => {
      state.influArrAddNewInflu = action.payload;
    },
    setTotalAmtObjAddNewInflu: (state, action) => {
      state.totalAmtObjAddNewInflu = action.payload;
    },
    setFormActiveStep: (state, action) => {
      state.formActiveStep = action.payload;
    },
    setFormNewInfActiveStep: (state, action) => {
      state.formNewInfActiveStep = action.payload;
    },
    resetRecruit: (state) => {
      state.type = '';
      state.companyName = '';
      state.campaignName = '';
      state.startingDate = new Date();
      state.endingDate = new Date();
      state.docUploadFile = null;
      state.docUploadUrl = '';
      state.brandPhilo = '';
      state.productToPromo = '';
      state.usp = '';
      state.ageRange = '';
      state.markSitua = '';
      state.objective = '';
      state.originalInfluArr = [];
      state.influArr = [];
      state.totalAmtObj = {
        storyAmt: 0,
        gridAmt: 0,
        igtvAmt: 0,
        total: 0,
      };
      state.originalInfluArrAddNewInflu = [];
      state.influArrAddNewInflu = [];
      state.totalAmtObjAddNewInflu = {
        storyAmt: 0,
        gridAmt: 0,
        igtvAmt: 0,
        total: 0,
      };
      state.formActiveStep = 0;
      state.formNewInfActiveStep = 0;
    },
  },
});

export const {
  setType,
  setCompanyName,
  setCampaignName,
  setStartingDate,
  setEndingDate,
  setDocUploadFile,
  setDocUploadUrl,
  setBrandPhilo,
  setProductToPromo,
  setUsp,
  setAgeRange,
  setMarkSitua,
  setObjective,
  setOriginalInfluArr,
  setInfluArr,
  setTotalAmtObj,
  setOriginalInfluArrAddNewInflu,
  setInfluArrAddNewInflu,
  setTotalAmtObjAddNewInflu,
  setFormActiveStep,
  setFormNewInfActiveStep,
  resetRecruit,
} = recruitSlice.actions;

export const selectRecruit = (state) => state.recruit;

export default recruitSlice.reducer;
