import { createSlice } from '@reduxjs/toolkit';

export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    aCampOpt: [],
    campIdChosen: '',
    campDetObj: {},
    displayOpt: {
      campaignsProject: true,
      campaignDetail: false,
    },
    updateAll: false,
    clientList: [],
  },
  reducers: {
    setACampOpt: (state, action) => {
      state.aCampOpt = action.payload;
    },
    setCampIdChosen: (state, action) => {
      state.campIdChosen = action.payload;
    },
    setCampDetObj: (state, action) => {
      state.campDetObj = action.payload;
    },
    setDisplayOpt: (state, action) => {
      state.displayOpt = action.payload;
    },
    setUpdateAll: (state, action) => {
      state.updateAll = action.payload;
    },
    setClientList: (state, action) => {
      state.clientList = action.payload;
    },
    resetClient: (state) => {
      state.aCampOpt = [];
      state.campIdChosen = '';
      state.campDetObj = {};
      state.displayOpt = {
        campaignsProject: true,
        campaignDetail: false,
      };
      state.updateAll = false;
      state.clientList = [];
    },
  },
});

export const { setACampOpt, setCampIdChosen, setCampDetObj, setDisplayOpt, setUpdateAll, setClientList, resetClient } =
  clientSlice.actions;

export const selectClient = (state) => state.client;

export default clientSlice.reducer;
