import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
const storage = firebase.storage();
const provider = new firebase.auth.FacebookAuthProvider();
provider.addScope(
  'public_profile,email,instagram_basic,instagram_manage_insights,pages_show_list,pages_read_engagement'
);
// const provider = new firebase.auth.GoogleAuthProvider();

export { auth, functions, firebaseApp, storage, provider };
export default db;
