import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';

import { initializeApp } from 'firebase/app';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

// firebase
import db, { functions, auth } from '../firebase';
import { FIREBASE_API } from '../config';
import LoadingScreenNormal from '../components/LoadingScreenNormal';
import { useDispatch, batch } from 'react-redux';
import { resetAdmin } from '../redux/slices/adminSlice';
import { resetClient } from '../redux/slices/clientSlice';
import { resetGlobal } from '../redux/slices/globalSlice';
import { resetInfluencerFilter } from '../redux/slices/InfluencerFiltersSlice';
import { resetInfluencer } from '../redux/slices/influencerSlice';
import { resetKeyword } from '../redux/slices/keywordSlice';
import { resetKpi } from '../redux/slices/kpiSlice';
import { resetPlanCap } from '../redux/slices/planningCaptionSlice';
import { resetPlan } from '../redux/slices/planningSlice';
import { resetRecruit } from '../redux/slices/recruitSlice';
import { resetRegister } from '../redux/slices/registerInfSlice';
import { resetSnackbar } from '../redux/slices/snackbarSlice';
import { resetUser } from '../redux/slices/userSlice';

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  } else if (action.type === 'LOGOUT') {
    return undefined;
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  login_inf: () => Promise.resolve(),
  logout_inf: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);
  const dispatchAction = useDispatch();

  const [profile, setProfile] = useState(null);

  useEffect(
    async () =>
      onAuthStateChanged(AUTH, async (user) => {
        setLoading(true);
        if (user) {
          // check if user is authenticated by facebook
          if (await isConFbk(user?.providerData)) {
            await processInfReg(user);
          } else {
            checkAuthUser(user);
          }
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null },
          });
          setLoading(false);
          resetAll();
        }
      }),
    [dispatch]
  );

  const processInfReg = async (user) => {
    // verify email address is present in influencer table
    let isPrsntObj = await chkIfEmailPrsnt(user?.email);
    if (isPrsntObj?.id !== '' && isPrsntObj?.id !== undefined) {
      // present
      if (isPrsntObj.data.access && !isPrsntObj.data.pendingReg) {
        setProfile(isPrsntObj?.data);
        dispatch({
          type: 'INITIALISE',
          payload: { isAuthenticated: true, user: { ...user, id: isPrsntObj?.id, role: 'influencer' } },
        });
        setLoading(false);
      } else if (isPrsntObj.data.pendingReg || isPrsntObj?.data?.role === 'pending-influencer') {
        dispatch({
          type: 'INITIALISE',
          payload: { isAuthenticated: true, user: { ...user, id: isPrsntObj?.id, role: 'pending-influencer' } },
        });
        setLoading(false);
      } else if (!isPrsntObj.data.access) {
        // no access page
        auth.signOut();
        resetAll();
        dispatch({
          type: 'INITIALISE',
          payload: { isAuthenticated: false, user: null },
        });
        window.location.href = '/noAccess';
        setLoading(false);
      }
    } else {
      // email not present
      dispatch({
        type: 'INITIALISE',
        payload: { isAuthenticated: true, user: { ...user, role: 'pending-influencer' } },
      });
      setLoading(false);
    }
  };

  const isConFbk = async (data) =>
    await new Promise((resolve) => {
      console.log('in isConFbk');
      if (data?.length > 0) {
        console.log('in data', data);
        if (data[0].providerId === 'facebook.com') {
          resolve(true);
        }
      }
      resolve(false);
    });

  const chkIfEmailPrsnt = async (email) =>
    await new Promise(async (resolve) => {
      await db
        .collection('influencer')
        .where('email', '==', email)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot?.docs?.length > 0) {
            let arr = [];
            querySnapshot?.docs?.forEach((doc) => {
              arr.push({
                id: doc.id,
                data: doc.data(),
              });
            });
            resolve({
              id: arr[0]?.id,
              data: arr[0].data,
            });
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log('Error getting documents: ', error);
        });
    });

  const checkAuthUser = (user) => {
    const isValid = functions.httpsCallable('checkUser');
    isValid({})
      .then(async (result) => {
        if (result.data.valid) {
          /*
          if (!user?.emailVerified) {
            //alert('Your email has not yet been verified');
            const auth = getAuth();
            sendEmailVerification(auth.currentUser).then(() => {
              // Email verification sent!
              alert(
                'Your email has not yet been verified. We sent you an email and please click the link to verify your email. Thank you.'
              );
              auth.signOut();
              setLoading(false);
            });
          } else {*/
          let userRef;
          if (result.data.role === 'client') {
            userRef = doc(DB, 'client', user.uid);
            // const docSnap = await getDoc(userRef);
            db.collection('client')
              .doc(user.uid)
              .onSnapshot((doc) => {
                if (doc?.data()) {
                  setProfile(doc?.data());
                }
              });

            dispatch({
              type: 'INITIALISE',
              payload: { isAuthenticated: true, user: { ...user, role: 'client' } },
            });
          } else if (result.data.role === 'influencer') {
            userRef = doc(DB, 'influencer', user.uid);
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
              setProfile(docSnap.data());
            }
            dispatch({
              type: 'INITIALISE',
              payload: { isAuthenticated: true, user: { ...user, role: 'influencer' } },
            });
          } else if (result.data.role === 'admin') {
            userRef = doc(DB, 'admin', user.uid);
            db.collection('admin')
              .doc(user.uid)
              .onSnapshot((doc) => {
                if (doc?.data()) {
                  setProfile(doc?.data());
                }
              });

            dispatch({
              type: 'INITIALISE',
              payload: { isAuthenticated: true, user: { ...user, role: 'admin' } },
            });
          }
          setLoading(false);
          // }
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null },
          });
          setLoading(false);
          // enqueueSnackbar("You don't have access to this site. Please contact an admin.", { variant: 'error' });
          auth.signOut();
          resetAll();
        }
      })
      .catch(() => {
        /* let msg = '';
        if (err.message) {
          msg = err.message;
        } else {
          msg = err;
        } */
        dispatch({
          type: 'INITIALISE',
          payload: { isAuthenticated: false, user: null },
        });
        setLoading(false);
        // enqueueSnackbar(msg, { variant: 'error' });
        auth.signOut();
        resetAll();
      });
  };

  const login = async (email, password) => {
    try {
      await auth.setPersistence('session').then(async () => {
        // await signInWithEmailAndPassword(AUTH, email, password);
        await auth.signInWithEmailAndPassword(email, password);
      });
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const login_inf = async (id, data, token, role, access) => {
    setProfile(data);
    let user = {
      uid: id,
      email: data?.email,
      role: role,
      token: token,
    };
    if (role === 'influencer') {
      if (access) {
        dispatch({
          type: 'INITIALISE',
          payload: { isAuthenticated: true, user: user },
        });
      } else {
        // display no access page
        window.location.href = '/noAccess';
      }
    } else {
      dispatch({
        type: 'INITIALISE',
        payload: { isAuthenticated: true, user: user },
      });
    }
  };

  const register = async (registerObj, type) => {
    const addUser = functions.httpsCallable('addUser');
    await addUser({ ...registerObj, type: type, role: type })
      .then(async () => {
        await auth
          .signInWithEmailAndPassword(registerObj.email, registerObj.password)
          .then(() => {
            console.log('Registration done successfully.');
          })
          .catch((error) => {
            console.log(error?.message);
          });
      })
      .catch((error) => {
        console.log(error?.message);
      });
  };

  const logout = () => {
    signOut(AUTH);
    dispatch({
      type: 'LOGOUT',
      //payload: { isAuthenticated: true, user: { ...user, role: 'client' } },
    });
    resetAll();
  };

  const logout_inf = () => {
    dispatch({
      type: 'INITIALISE',
      payload: { isAuthenticated: false, user: null },
    });
  };

  const resetAll = () => {
    batch(() => {
      dispatchAction(resetAdmin());
      dispatchAction(resetClient());
      dispatchAction(resetGlobal());
      dispatchAction(resetInfluencerFilter());
      dispatchAction(resetInfluencer());
      dispatchAction(resetKeyword());
      dispatchAction(resetKpi());
      dispatchAction(resetPlanCap());
      dispatchAction(resetPlan());
      dispatchAction(resetRecruit());
      dispatchAction(resetRegister());
      dispatchAction(resetSnackbar());
      dispatchAction(resetUser());
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: state?.user?.uid,
          email: profile?.email || '',
          photoURL:
            state?.user?.role === 'influencer' && profile?.photoURL
              ? profile?.photoURL
              : state?.user?.role === 'influencer'
              ? profile?.info?.profilePicUrl
              : profile?.photoURL,
          displayName:
            state?.user?.role === 'influencer'
              ? profile?.info?.name
              : state?.user?.role === 'client'
              ? profile?.companyName
              : state?.user?.role === 'admin'
              ? profile?.firstName + ' ' + profile?.lastName
              : state?.user?.role === 'pending-influencer'
              ? profile?.name
              : state?.user?.displayName,
          role: state?.user?.role,
          phoneNumber: profile?.contactNumber,
          vat: state?.user?.role === 'client' ? profile?.vat : '',
          brn: state?.user?.role === 'client' ? profile?.brn : '',
          country: profile?.country || '',
          address: profile?.address || '',
          state: profile?.state || '',
          city: profile?.city || '',
          zipCode: profile?.zipCode || '',
          about: profile?.about || '',
          isPublic: profile?.isPublic || false,
          token:
            state?.user?.role === 'influencer' || state?.user?.role === 'pending-influencer' ? state?.user?.token : '',
        },
        login,
        login_inf,
        register,
        logout,
        logout_inf,
      }}
    >
      {loading ? <LoadingScreenNormal /> : ''}
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
