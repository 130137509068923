import PropTypes from 'prop-types';
// @mui
import { Box, Card, Link, Typography, Stack, IconButton, Tooltip } from '@mui/material';
// components
import Image from '../../../../../../components/Image';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useDispatch, useSelector } from 'react-redux';
import { selectRecruit, setInfluArr, setInfluArrAddNewInflu } from '../../../../../../redux/slices/recruitSlice';
import { Suspense, useEffect, useState, lazy, useRef } from 'react';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import db from '../../../../../../firebase';
import { useSnackbar } from 'notistack';
import { setLoading } from '../../../../../../redux/slices/globalSlice';
import moment from 'moment';

// ---------------------------------------------------------------------

const InfInsights = lazy(() => import('./InfInsights'));

InfluencerCard.propTypes = {
  type: PropTypes.string,
  influencer: PropTypes.object,
};

export default function InfluencerCard({ type, influencer }) {
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const { id, data } = influencer;

  const { influArr, influArrAddNewInflu } = useSelector(selectRecruit);

  const [us_a_influ, set_us_a_influ] = useState([]);

  const [us_b_opn_ins_dlg, set_us_b_opn_ins_dlg] = useState(false);

  const [audCity, setAudCity] = useState({});

  const [audCountry, setAudCountry] = useState({});

  const [us_a_gender, set_us_a_gender] = useState();

  const [us_o_media_data, set_us_o_media_data] = useState({
    a_detail: [],
    a_dates: [],
    a_eng_val: [],
    a_imp_val: [],
    a_rch_val: [],
    a_sve_val: [],
  });

  const temp_influencers = useRef();

  useEffect(() => {
    // know which influencer array to use
    temp_influencers.current();
  }, [type, influArr, influArrAddNewInflu]);

  const initInfluArr = () => {
    if (type === 'add_new') {
      set_us_a_influ([...influArrAddNewInflu]);
    } else {
      set_us_a_influ([...influArr]);
    }
  };

  temp_influencers.current = initInfluArr;

  const selectInflu = (id) => {
    let newInfluArr = [];
    us_a_influ.forEach((inf) => {
      if (inf.id === id) {
        newInfluArr.push({ ...inf, data: { ...data, selected: true } });
      } else {
        newInfluArr.push(inf);
      }
    });

    if (newInfluArr?.length === us_a_influ.length) {
      if (type === 'add_new') {
        dispatch(setInfluArrAddNewInflu(newInfluArr));
      } else {
        dispatch(setInfluArr(newInfluArr));
      }
    }
  };

  const unSelectInflu = (id) => {
    let newInfluArr = [];
    us_a_influ.forEach((inf) => {
      if (inf.id === id) {
        newInfluArr.push({ ...inf, data: { ...data, selected: false } });
      } else {
        newInfluArr.push(inf);
      }
    });

    if (type === 'add_new') {
      dispatch(setInfluArrAddNewInflu(newInfluArr));
    } else {
      dispatch(setInfluArr(newInfluArr));
    }
  };

  const open_ins_dlg = async (id) => {
    dispatch(setLoading(true));
    await db
      .collection('inf_insight')
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log(doc.data());

          let a_date_format = [];
          let a_date_format_test = [];
          if (doc?.data()?.a_media_dates?.length > 0) {
            doc?.data()?.a_media_dates?.forEach((o_date) => {
              if (o_date.seconds) {
                // a_date_format.push(moment(new Date(o_date?.seconds * 1000)));
                a_date_format_test.push({ date: moment(new Date(o_date?.seconds * 1000)) });
              }
            });

            let new_a_media_detail = [...doc?.data()?.a_media_detail];

            new_a_media_detail.sort(dynamicSort('timestamp'));
            a_date_format_test.sort(dynamicSort('date'));

            a_date_format_test.forEach((o_date) => {
              a_date_format.push(o_date?.date);
            });

            set_us_o_media_data({
              a_detail: new_a_media_detail || [], // doc?.data()?.a_media_detail || [],
              a_dates: a_date_format || [],
              a_eng_val: doc?.data()?.a_media_eng_val || [],
              a_imp_val: doc?.data()?.a_media_imp_val || [],
              a_rch_val: doc?.data()?.a_media_rch_val || [],
              a_sve_val: doc?.data()?.a_media_sve_val || [],
            });
          }

          setAudCity(doc?.data()?.a_aud_city || []);
          setAudCountry(doc?.data()?.a_aud_coun || []);
          set_us_a_gender(doc?.data()?.a_aud_gen_age || []);

          set_us_b_opn_ins_dlg(true);
          dispatch(setLoading(false));
        } else {
          enqueueSnackbar('No data retrieved for this influencer.', { variant: 'error' });
          dispatch(setLoading(false));
        }
      });
  };

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a, b) => {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const handleCloseDlg = () => {
    set_us_b_opn_ins_dlg(false);
  };

  return (
    <>
      <Card>
        <Box sx={{ position: 'relative' }}>
          <Image alt={data?.info?.name} src={data?.info?.profilePicUrl} ratio="1/1" />
        </Box>

        <Stack spacing={2} sx={{ p: 3 }} direction="column" justifyContent={'space-between'}>
          <Stack>
            <Typography variant="subtitle2" noWrap>
              {data?.info?.name}
            </Typography>
            <Link
              href={`https://www.instagram.com/${data?.instagramUsername}`}
              color="inherit"
              variant="caption"
              target="_blank"
            >
              {data?.instagramUsername}
            </Link>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {data?.selected ? (
              <IconButton onClick={() => unSelectInflu(id)}>
                <Tooltip title="Influencer has been selected">
                  <CheckCircleRoundedIcon fontSize="large" color="success" />
                </Tooltip>
              </IconButton>
            ) : (
              <IconButton onClick={() => selectInflu(id)}>
                <Tooltip title="Click here to select influencer">
                  <ArrowCircleUpIcon fontSize="large" color="warning" />
                </Tooltip>
              </IconButton>
            )}

            <IconButton onClick={() => open_ins_dlg(id)}>
              <Tooltip title="See analytics of the influencer">
                <AutoGraphIcon fontSize="large" color="info" />
              </Tooltip>
            </IconButton>
          </Stack>
        </Stack>
      </Card>

      <Suspense fallback={<></>}>
        <InfInsights
          us_b_opn_ins_dlg={us_b_opn_ins_dlg}
          handleCloseDlg={handleCloseDlg}
          audCity={audCity}
          audCountry={audCountry}
          us_o_media_data={us_o_media_data}
          us_a_gender={us_a_gender}
        />
      </Suspense>
    </>
  );
}
