import { Suspense, lazy, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Link, Container, Typography, Tabs, Tab } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
import useResponsive from '../../hooks/useResponsive';
import { CheckInfluencer } from '../../sections/auth/registerAsInfluencer';
// components

const Page = lazy(() => import('../../components/Page'));
const Logo = lazy(() => import('../../components/Logo'));
const LoginForm = lazy(() => import('../../sections/auth/login/LoginForm'));

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive('up', 'sm');

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  return (
    <Suspense fallback={<></>}>
      <Page title="Login">
        <RootStyle>
          <HeaderStyle>
            <Suspense fallback={<></>}>
              <Logo sx={{ mt: { md: -2 } }} />
            </Suspense>
            {smUp && (
              <>
                <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                  Create company account? {''}
                  <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                    Get started
                  </Link>
                  <br />
                  Create influencer account? {''}
                  <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.registerAsInfluencer}>
                    Get started
                  </Link>
                </Typography>
              </>
            )}
          </HeaderStyle>

          <Container maxWidth="sm">
            <ContentStyle>
              <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4" gutterBottom>
                    Sign in to KonekTwa
                  </Typography>
                  <br />
                  <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <Tabs variant="fullWidth" centered={false} value={value} onChange={handleChange}>
                      <Tab label={<Typography>Login as company</Typography>} />
                      <Tab label={<Typography>Login as Influencer</Typography>} />
                    </Tabs>
                  </Box>
                  <br />
                  {!value ? (
                    <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
                  ) : (
                    <Typography sx={{ color: 'text.secondary' }}>Please log in with your facebook account.</Typography>
                  )}
                </Box>
              </Stack>

              {!value ? (
                <Suspense fallback={<></>}>
                  <LoginForm />
                </Suspense>
              ) : (
                <CheckInfluencer type={'LOGIN'} />
              )}

              {!smUp && (
                <>
                  <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                    Create company account? {''}
                    <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                      Get started
                    </Link>
                    <br />
                    Create influencer account? {''}
                    <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.registerAsInfluencer}>
                      Get started
                    </Link>
                  </Typography>
                </>
              )}
            </ContentStyle>
          </Container>
        </RootStyle>
      </Page>
    </Suspense>
  );
}
