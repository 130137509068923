import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormControl, ListItemIcon, ListItemText, MenuItem, Select, Checkbox, InputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import db from '../../firebase';
import { selectKeyword, setKeywordList } from '../../redux/slices/keywordSlice';

//  ----------------------------------------------------------------------

RHFSelectMulti.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  // options: PropTypes.arrayOf(PropTypes.string),
};

const useStyles = makeStyles(() => ({
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
};

// const options = ['CSG', 'NSF', 'LEVY', 'NONE'];

export default function RHFSelectMulti({ name, /* options */ disabled }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { control } = useFormContext();
  const dispatch = useDispatch();
  const keywordSlice = useSelector(selectKeyword);
  const { keywordList } = keywordSlice;

  const [options, setOptions] = useState([]);
  const [selectedNoEdfContri, setSelectedNoEdfContri] = useState([]);
  const isAllSelected = options.length > 0 && selectedNoEdfContri.length === options.length;

  useEffect(() => {
    initialiseKeywords();
  }, []);

  const initialiseKeywords = () => {
    if (keywordList && keywordList.length === 0) {
      db.collection('keyword')
        .doc('keywordArray')
        .onSnapshot(
          (doc) => {
            let arr = [];
            arr = [...doc.data().keywordArr];
            setOptions(arr);
            dispatch(setKeywordList(arr));
          },
          (error) => {
            enqueueSnackbar(`Error occured while fetching keywords: ${error?.message}`, { variant: 'error' });
          }
        );
    } else {
      setOptions([...keywordList]);
    }
  };

  const handleChangeMultipleSelect = (event) => {
    const { value } = event.target;
    if (value[value.length - 1] === 'all') {
      setSelectedNoEdfContri(selectedNoEdfContri.length === options.length ? [] : options);
    } else {
      setSelectedNoEdfContri(value);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <FormControl fullWidth variant="outlined">
          <InputLabel id="mutiple-select-label" required>
            {name}
          </InputLabel>
          <Select
            label={name}
            labelId="mutiple-select-label"
            multiple
            value={selectedNoEdfContri}
            onChange={handleChangeMultipleSelect}
            renderValue={(selectedNoEdfContri) => selectedNoEdfContri.join(', ')}
            MenuProps={MenuProps}
            disabled={disabled}
          >
            <MenuItem
              value="all"
              classes={{
                root: isAllSelected ? classes.selectedAll : '',
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{
                    indeterminate: classes.indeterminateColor,
                  }}
                  checked={isAllSelected}
                  indeterminate={selectedNoEdfContri.length > 0 && selectedNoEdfContri.length < options.length}
                />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                <ListItemIcon>
                  <Checkbox checked={selectedNoEdfContri.indexOf(option) > -1} />
                </ListItemIcon>
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
