import { Suspense, lazy, useEffect } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import AdminGuard from '../guards/AdminGuard';
import ClientGuard from '../guards/ClientGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import LoadingScreenCustom from '../components/LoadingScreenCustom';
import StartCampaign from '../pages/dashboard/Client/start-a-campaign';
import useAuth from '../hooks/useAuth';
import InfluencerGuard from '../guards/InfluencerGuard';
import PendingInfGuard from '../guards/PendingInfGuard';
import PageNoAccess from '../pages/PageNoAccess';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { selectSnackbar } from '../redux/slices/snackbarSlice';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <>
        <LoadingScreenCustom />
        <Component {...props} />
      </>
    </Suspense>
  );
};

export default function Router() {
  const { user } = useAuth();

  const { enqueueSnackbar } = useSnackbar();
  const snackbar = useSelector(selectSnackbar);

  useEffect(() => {
    // snackbar to display the error and success messages
    if (snackbar && snackbar.message !== '' && snackbar.variant !== '') {
      let variant = snackbar?.variant;
      enqueueSnackbar(snackbar?.message, { variant });
    }
  }, [snackbar, enqueueSnackbar]);

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: 'register-as-influencer',
          element: (
            <GuestGuard>
              <RegisterAsInfluencer />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: (
            <Navigate
              to={
                user && user.id !== '' && user.role === 'admin'
                  ? '/dashboard/admin-main'
                  : user && user.id !== '' && user.role === 'client'
                  ? '/dashboard/my-campaign'
                  : user && user.id !== '' && user.role === 'influencer'
                  ? '/dashboard/influencer-dashboard'
                  : user && user.id !== '' && user.role === 'pending-influencer'
                  ? '/auth/register-as-influencer-form'
                  : '' /* PATH_AFTER_LOGIN */
              }
              replace
            />
          ),
          index: true,
        },
        // ADMIN PART
        {
          path: 'admin-main',
          element: (
            <AdminGuard>
              <AdminDashboard />
            </AdminGuard>
          ),
        },
        {
          path: 'manage-keyword',
          element: (
            <AdminGuard>
              <Suspense fallback={<></>}>
                <ManageKeywords />
              </Suspense>
            </AdminGuard>
          ),
        },
        {
          path: 'manage-influencer',
          element: (
            <AdminGuard>
              <Suspense fallback={<></>}>
                <ManageInfluencer />
              </Suspense>
            </AdminGuard>
          ),
        },
        {
          path: 'manage-admin',
          element: (
            <AdminGuard>
              <Suspense fallback={<></>}>
                <ManageAdmin />
              </Suspense>
            </AdminGuard>
          ),
        },
        {
          path: 'my-account-admin',
          element: (
            <AdminGuard>
              <UserAccount />
            </AdminGuard>
          ),
        },
        {
          path: 'selected-campaign',
          element: (
            <AdminGuard>
              <Suspense fallback={<></>}>
                <CampSelected />
              </Suspense>
            </AdminGuard>
          ),
        },
        {
          path: 'chat',
          children: [
            {
              element: (
                <AdminGuard>
                  <Chat />
                </AdminGuard>
              ),
              index: true,
            },
            {
              path: 'new',
              element: (
                <AdminGuard>
                  <Chat />
                </AdminGuard>
              ),
            },
            {
              path: ':conversationKey',
              element: (
                <AdminGuard>
                  <Chat />
                </AdminGuard>
              ),
            },
          ],
        },
        // CLIENT PART
        {
          path: 'my-campaign',
          element: (
            <ClientGuard>
              <ClientDashboard />
            </ClientGuard>
          ),
        },
        {
          path: 'campaign-detail',
          element: (
            <ClientGuard>
              <ClientCampaignDetails />
            </ClientGuard>
          ),
        },
        {
          path: 'start-a-campaign',
          element: (
            <ClientGuard>
              <StartCampaign />
            </ClientGuard>
          ),
        },
        {
          path: 'my-account-client',
          element: (
            <ClientGuard>
              <UserAccount />
            </ClientGuard>
          ),
        },
        // INFLUENCER PART
        {
          path: 'influencer-dashboard',
          element: (
            <InfluencerGuard>
              <InfluencerDashboard />
            </InfluencerGuard>
          ),
        },
        {
          path: 'inf-campaign-detail',
          element: (
            <InfluencerGuard>
              <InfCampaignDetails />
            </InfluencerGuard>
          ),
        },
        {
          path: 'my-account-influencer',
          element: (
            <InfluencerGuard>
              <UserAccount />
            </InfluencerGuard>
          ),
        },
        {
          path: 'register-as-influencer-form',
          element: (
            <PendingInfGuard>
              <ProcceedToInfRegistration />
            </PendingInfGuard>
          ),
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'privacypolicy', element: <PrivacyPolicy /> },
        { path: 'termsconditions', element: <TermsCond /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: 'noAccess', element: <PageNoAccess /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const RegisterAsInfluencer = Loadable(lazy(() => import('../pages/auth/RegisterAsInfluencer')));
const ProcceedToInfRegistration = Loadable(lazy(() => import('../pages/auth/ProcceedToInfRegistration')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const AdminDashboard = Loadable(lazy(() => import('../pages/dashboard/admin/AdminDashboard')));
const ManageAdmin = lazy(() => import('../pages/dashboard/admin/manageAdmin/AdminDetail'));
const CampSelected = lazy(() => import('../pages/dashboard/admin/selected_campaign/CampSelected'));
// manage keywords
const ManageKeywords = lazy(() => import('../pages/dashboard/admin/manageKeywords/ManageKeywords'));
// manage influencer
const ManageInfluencer = lazy(() => import('../pages/dashboard/admin/influencer/ManageInfluencers'));
//client
const ClientDashboard = Loadable(lazy(() => import('../pages/dashboard/Client/ClientDashboard')));
const ClientCampaignDetails = Loadable(lazy(() => import('../pages/dashboard/Client/ClientCampaignDetails')));
//influencer
const InfluencerDashboard = Loadable(lazy(() => import('../pages/dashboard/Influencer/InfluencerDashboard')));
const InfCampaignDetails = Loadable(
  lazy(() => import('../pages/dashboard/Influencer/campaignInfo/InfCampaignDetails'))
);
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
// Main
const PrivacyPolicy = Loadable(lazy(() => import('../pages/PrivacyPolicy')));
const TermsCond = Loadable(lazy(() => import('../pages/TermsCond')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
