import { useState } from 'react';
import './Slider.css';
import Slide1 from './Slide1';
import useResponsive from '../../../../../hooks/useResponsive';
import Slide2 from './Slide2';
import Slide3 from './Slide3';
import Slide6 from './Slide6';
import Slide5 from './Slide5';
import Slide4 from './Slide4';

const NewCampDet = () => {
  const isDesktop = useResponsive('up', 'md');

  const [currentState, setCurrentState] = useState({ current: 0 });

  const { current } = currentState;

  const wrapperTransform = {
    transform: `translateX(-${current * (100 / 6)}%)`,
  };

  const handlePreviousClick = () => {
    const previous = current - 1;

    setCurrentState({
      current: previous < 0 ? 6 - 1 : previous,
    });
  };

  const handleNextClick = () => {
    const next = current + 1;

    setCurrentState({
      current: next === 6 ? 0 : next,
    });
  };

  return (
    <div
      className="slider"
      style={{
        height: isDesktop ? '50vmin' : '100vmin',
        width: isDesktop ? '50vmin' : '100vmin',
      }}
    >
      <ul className="slider__wrapper" style={wrapperTransform}>
        <Slide1 key={0} index={0} current={current} handleNextClick={handleNextClick} />
        <Slide2
          key={1}
          index={1}
          current={current}
          handleNextClick={handleNextClick}
          handlePreviousClick={handlePreviousClick}
        />
        <Slide3
          key={2}
          index={2}
          current={current}
          handleNextClick={handleNextClick}
          handlePreviousClick={handlePreviousClick}
        />
        <Slide4
          key={3}
          index={3}
          current={current}
          handleNextClick={handleNextClick}
          handlePreviousClick={handlePreviousClick}
        />
        <Slide5
          key={4}
          index={4}
          current={current}
          handleNextClick={handleNextClick}
          handlePreviousClick={handlePreviousClick}
        />
        <Slide6
          key={5}
          index={5}
          current={current}
          handleNextClick={handleNextClick}
          handlePreviousClick={handlePreviousClick}
        />
      </ul>
    </div>
  );
};

export default NewCampDet;
