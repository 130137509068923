import { createSlice } from '@reduxjs/toolkit';

export const messagingSlice = createSlice({
  name: 'messaging',
  initialState: {
    contacts: { byId: {}, allIds: [] },
    activeConversationId: '',
    conversations: { byId: {}, allIds: [] },
  },
  reducers: {
    setContacts: (state, action) => {
      const contactsArr = action.payload;
      state.contacts.byId = contactsArr;
      state.contacts.allIds = Object.keys(state.contacts.byId);
    },
    setActiveConversationId: (state, action) => {
      state.activeConversationId = action.payload;
    },
    setConversations: (state, action) => {
      state.conversations = action.payload;
    },

    // ON SEND MESSAGE
    onSendMessage(state, action) {
      const conversation = action.payload;
      const { conversationId, messageId, message, contentType, attachments, createdAt, senderId } = conversation;

      const newMessage = {
        id: messageId,
        body: message,
        contentType,
        attachments,
        createdAt,
        senderId,
      };

      state.conversations.byId[conversationId].messages.push(newMessage);
    },
  },
});

export const { setContacts, setActiveConversationId, setConversations, onSendMessage } = messagingSlice.actions;

export const selectMessaging = (state) => state.messaging;

export default messagingSlice.reducer;
