import { DialogContent, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';

ViewProfilePic.propTypes = {
  picUrl: PropTypes.string,
};

export default function ViewProfilePic({ picUrl }) {
  return (
    <>
      <DialogTitle align="center">Influencer's profile picture</DialogTitle>
      <DialogContent style={{ minHeight: 'auto' }}>
        <img src={picUrl} alt="influencer profile pic" width="100%" height="auto" loading="lazy" />
      </DialogContent>
    </>
  );
}
