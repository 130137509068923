import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import chatReducer from './slices/chat';
import calendarReducer from './slices/calendar';
import userReducer from './slices/userSlice';
import snackbarReducer from './slices/snackbarSlice';
import globalReducer from './slices/globalSlice';
import adminReducer from './slices/adminSlice';
import influencerReducer from './slices/influencerSlice';
import keywordReducer from './slices/keywordSlice';
import clientReducer from './slices/clientSlice';
import recruitReducer from './slices/recruitSlice';
import planningReducer from './slices/planningSlice';
import planningCaptionReducer from './slices/planningCaptionSlice';
import kpiReducer from './slices/kpiSlice';
import influencerFiltersReducer from './slices/InfluencerFiltersSlice';
import messagingReducer from './slices/messagingSlice';
import registerInfReducer from './slices/registerInfSlice';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

/* const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
}; */

const rootReducer = combineReducers({
  // mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  // kanban: kanbanReducer,
  // product: persistReducer(productPersistConfig, productReducer),
  user: userReducer,
  snackbar: snackbarReducer,
  global: globalReducer,
  admin: adminReducer,
  influencer: influencerReducer,
  keyword: keywordReducer,
  client: clientReducer,
  recruit: recruitReducer,
  planning: planningReducer,
  planningCaption: planningCaptionReducer,
  kpi: kpiReducer,
  influencerFilters: influencerFiltersReducer,
  messaging: messagingReducer,
  registerInf: registerInfReducer,
});

export { rootPersistConfig, rootReducer };
