import { createSlice } from '@reduxjs/toolkit';

export const keywordSlice = createSlice({
  name: 'keyword',
  initialState: {
    keywordList: [],
  },
  reducers: {
    setKeywordList: (state, action) => {
      state.keywordList = action.payload;
    },
    resetKeyword: (state) => {
      state.keywordList = [];
    },
  },
});

export const { setKeywordList, resetKeyword } = keywordSlice.actions;

export const selectKeyword = (state) => state.keyword;

export default keywordSlice.reducer;
