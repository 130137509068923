import PropTypes from 'prop-types';
import './Slider.css';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isBefore } from 'date-fns';
import { styled } from '@mui/styles';
// @mui
import { Stack, TextField, Alert, Card, CardHeader, Typography } from '@mui/material';
import useIsMountedRef from '../../../../../hooks/useIsMountedRef';

// redux
import { useDispatch, useSelector } from '../../../../../redux/store';
import {
  selectRecruit,
  setEndingDate,
  setStartingDate,
  setCampaignName,
  setCompanyName,
} from '../../../../../redux/slices/recruitSlice';
import { setLoading } from '../../../../../redux/slices/globalSlice';
import { FormProvider, RHFTextField } from '../../../../../components/hook-form';
import { LoadingButton, MobileDatePicker } from '@mui/lab';
import { useEffect, useRef, useState } from 'react';
import db from '../../../../../firebase';
import useAuth from '../../../../../hooks/useAuth';
import { useSnackbar } from 'notistack';
import useResponsive from '../../../../../hooks/useResponsive';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

function Slide1({ current, index, handleNextClick }) {
  const dispatch = useDispatch();

  const isDesktop = useResponsive('up', 'md');

  const slideRef = useRef(null);

  const isMountedRef = useIsMountedRef();

  const { user } = useAuth();

  const { companyName, campaignName, startingDate, endingDate } = useSelector(selectRecruit);

  const { enqueueSnackbar } = useSnackbar();

  const [companyNameInput, setCompanyNameInput] = useState(companyName !== '' ? companyName : '');

  const RegistersSchema = Yup.object().shape({
    campaignName: Yup.string().required('Campaign name required'),
    startingDate: Yup.date().required('Start date of campaign required'),
    endingDate: Yup.date().required('End date of campaign required'),
  });

  let defaultValues = {
    campaignName: campaignName !== '' ? campaignName : '',
    startingDate: startingDate !== '' ? startingDate : new Date(),
    endingDate: endingDate !== '' ? endingDate : new Date(),
  };

  const temp_comp_name = useRef();

  useEffect(() => {
    temp_comp_name.current();
  }, []);

  let classNames = 'slide';

  if (current === index) classNames += ' slide--current';
  else if (current - 1 === index) classNames += ' slide--previous';
  else if (current + 1 === index) classNames += ' slide--next';

  const initialiseCompanyName = async () => {
    dispatch(setLoading(true));
    await db
      .collection('client')
      .doc(user?.id)
      .get()
      .then((doc) => {
        dispatch(setCompanyName(doc.data().companyName));
        setCompanyNameInput(doc.data().companyName);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        enqueueSnackbar(`Error fetching user details: ${err?.message}`, { variant: 'error' });
        dispatch(setLoading(false));
      });
  };

  temp_comp_name.current = initialiseCompanyName;

  const methods = useForm({
    resolver: yupResolver(RegistersSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      dispatch(setCompanyName(companyNameInput));
      dispatch(setCampaignName(data.campaignName));
      dispatch(setStartingDate(data.startingDate));
      dispatch(setEndingDate(data.endingDate));

      // setStep((step) => step + 1);
      handleNextClick();
    } catch (error) {
      console.error(error);
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', error);
      }
    }
  };

  const values = watch();

  const isDateError = isBefore(new Date(values.end), new Date(values.start));

  return (
    <li
      ref={slideRef}
      className={classNames}
      style={{ width: isDesktop ? '50vmin' : '75vmin' }}
      // onClick={handleSlideOnClick}
      // onKeyDown={handleSlideOnClick}
      // onMouseMove={handleMouseMove}
      // onMouseLeave={handleMouseLeave}
    >
      <Card sx={{ p: 3, /* mb: 3, */ minHeight: '450px' }}>
        <CardHeader title={<LabelStyle>1) a. Please fill in these compulsory fields</LabelStyle>} sx={{ mb: 3 }} />

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
            <RHFTextField name="campaignName" label="Campaign name" required />
            {/*
        <RHFTextField name="companyName" label="Company name" required />
        */}

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="companyName"
              label="Company Name"
              type="text"
              id="companyName"
              value={companyNameInput}
              onChange={(event) => {
                setCompanyNameInput(event.target.value);
              }}
              disabled={current !== index}
            />
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Controller
                name="startingDate"
                control={control}
                required
                render={({ field }) => (
                  <MobileDatePicker
                    {...field}
                    label="Start date of the campaign"
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} fullWidth disabled={current !== index} />}
                  />
                )}
              />

              <Controller
                name="endingDate"
                control={control}
                render={({ field }) => (
                  <MobileDatePicker
                    {...field}
                    label="End date of campaign"
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={!!isDateError}
                        helperText={isDateError && 'End date must be later than start date'}
                        disabled={current !== index}
                      />
                    )}
                  />
                )}
              />
            </Stack>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={current !== index}
            >
              Proceed
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Card>
    </li>
  );
}

// ----------------------------------------------------------------------

Slide1.propTypes = {
  current: PropTypes.number,
  index: PropTypes.number,
  handleNextClick: PropTypes.func,
};

export default Slide1;
