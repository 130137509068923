import { createSlice } from '@reduxjs/toolkit';

export const kpiSlice = createSlice({
  name: 'kpi',
  initialState: {
    postsKpi: [],
    totEng: 0,
  },
  reducers: {
    setPostsKpi: (state, action) => {
      state.postsKpi = action.payload;
    },
    setTotEng: (state, action) => {
      state.totEng = action.payload;
    },
    resetKpi: (state) => {
      state.postsKpi = [];
      state.totEng = 0;
    },
  },
});

export const { setPostsKpi, setTotEng, resetKpi } = kpiSlice.actions;

export const selectKpi = (state) => state.kpi;

export default kpiSlice.reducer;
