import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Grid, Step, Stepper, Container, StepLabel, StepConnector } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../../../redux/store';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
// components
import Page from '../../../../components/Page';
import Iconify from '../../../../components/Iconify';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { selectRecruit, setInfluArr, setType } from '../../../../redux/slices/recruitSlice';
import InfluencerDetails from './steps/InfluencerSection/InfluencerDetails';
import { selectInfluencer, setInfluencerList } from '../../../../redux/slices/influencerSlice';
import db from '../../../../firebase';
import PricingDetails from './steps/Pricing/PricingDetails';
import { selectClient, setACampOpt } from '../../../../redux/slices/clientSlice';
import { setLoading } from '../../../../redux/slices/globalSlice';
import useAuth from '../../../../hooks/useAuth';
import NewCampDet from './steps/NewCampDet';

// ----------------------------------------------------------------------

const STEPS = ['1) Campaign details - (a - e)', '2) Influencer details', '3) Confirmation'];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  top: 10,
  left: 'calc(-50% + 20px)',
  right: 'calc(50% + 20px)',
  '& .MuiStepConnector-line': {
    borderTopWidth: 2,
    borderColor: theme.palette.divider,
  },
  '&.Mui-active, &.Mui-completed': {
    '& .MuiStepConnector-line': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

function QontoStepIcon({ active, completed }) {
  return (
    <Box
      sx={{
        zIndex: 9,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: active ? 'primary.main' : 'text.disabled',
      }}
    >
      {completed ? (
        <Iconify icon={'eva:checkmark-fill'} sx={{ zIndex: 1, width: 20, height: 20, color: 'primary.main' }} />
      ) : (
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
          }}
        />
      )}
    </Box>
  );
}

export default function StartCampaign() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { influencerList } = useSelector(selectInfluencer);
  const { aCampOpt } = useSelector(selectClient);
  const { formActiveStep, influArr } = useSelector(selectRecruit);
  const isComplete = formActiveStep === STEPS.length;

  const temp_recruit_data = useRef();

  const temp_camps = useRef();

  useEffect(() => {
    temp_recruit_data.current();
  }, []);

  /**
   * optimisation redux
   * in case the user enter the start a new campaign directly via url -> this will result in aCampOpt not filled with data
   * in case aCampOpt is empty -> execute function
   */
  useEffect(() => {
    temp_camps.current();
  }, []);
  /**
   * load all campaigns for this client
   * use the a_camp array to optimise the loading of campaigns
   * listen to reltime updates of the doc a_camp
   * */
  const _ue_init_camp = () => {
    /**
     * trigger db action if aCampOpt is empty
     * note that on first load of the app , the db action will trigger
     * note that for the realtime updates, only the snapshot will trigger if there are new updates in the a_camp
     *  */
    if (user?.id !== '' && aCampOpt?.length === 0) {
      dispatch(setLoading(true));
      db.collection('client')
        .doc(user?.id)
        .collection('campaign')
        .doc('a_camp')
        .onSnapshot((doc) => {
          let a_camp = doc?.data()?.a_camp;

          if (a_camp?.length > 0) {
            /**
             * 1 - setACampOpt used in CardWidget to get the array of campaigns in function _on_sve_opn_tim
             */
            dispatch(setACampOpt(a_camp));
            dispatch(setLoading(false));
          } else {
            dispatch(setLoading(false));
          }
        });
    }
  };

  temp_camps.current = _ue_init_camp;

  const initialiseRecruitData = () => {
    if (influencerList?.length === 0 || influArr?.length === 0) {
      dispatch(setLoading(true));
      db.collection('influencer')
        .where('access', '==', true)
        // .where('info.profilePicUrl', '!=', '')
        .onSnapshot((snapshot) => {
          if (snapshot && snapshot.docs && snapshot.docs.length > 0) {
            var arr = [];
            snapshot.docs.forEach((doc) => {
              if (
                doc.data().access &&
                doc.data()?.info?.profilePicUrl !== undefined &&
                doc.data()?.info?.profilePicUrl !== '' /* doc.data().status !== 'not_valid' */
              ) {
                arr.push({ id: doc.id, data: doc.data() });
              }
            });
            dispatch(setInfluencerList(arr));
            dispatch(setInfluArr(arr));
          } else {
            dispatch(setInfluencerList([]));
            dispatch(setInfluArr([]));
          }
          dispatch(setLoading(false));
        });
    }
    dispatch(setType('recruit'));
  };

  temp_recruit_data.current = initialiseRecruitData;

  return (
    <Page title="Start a campaign">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Start a campaign"
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Start a campaign' }]}
        />

        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={12 /* formActiveStep === 0 ? 8 : 12 */} sx={{ mb: 5 /* position: 'fixed'  */ }}>
            <Stepper alternativeLabel activeStep={formActiveStep} connector={<QontoConnector />}>
              {STEPS.map((label) => (
                <Step key={label}>
                  <StepLabel
                    StepIconComponent={QontoStepIcon}
                    sx={{
                      '& .MuiStepLabel-label': {
                        typography: 'subtitle2',
                        color: 'text.disabled',
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <br />
            {!isComplete ? (
              <>
                {formActiveStep === 0 && (
                  <>
                    <div id="outerDiv">
                      <NewCampDet />
                    </div>
                  </>
                )}
                {formActiveStep === 1 && (
                  <div style={{ marginTop: '1em' }}>
                    <InfluencerDetails />
                  </div>
                )}
                {formActiveStep === 2 && (
                  <div style={{ marginTop: '1em' }}>
                    <PricingDetails />
                  </div>
                )}
              </>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
