import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
// @mui
import {
  Stack,
  Alert,
  Avatar,
  TextField,
  Button,
  FormControl,
  RadioGroup,
  Radio,
  Typography,
  Checkbox,
  Select,
  MenuItem,
  FormControlLabel,
  InputLabel,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setMsg } from '../../../redux/slices/globalSlice';
// hooks
import { calculateInfluencerData } from '../../coreFunctions/CoreFunctions';
import firebase from 'firebase/compat';
import db, { auth, functions } from '../../../firebase';
import useAuth from '../../../hooks/useAuth';
import {
  selectRegisterInf,
  setInfData,
  setPages,
  setSelectedPageId,
  setSelectedPageName,
  setLoggedInFbBusAccId,
  setLoggedInFbId,
  setLoggedInFbToken,
  setLoggedInFbPageId,
} from '../../../redux/slices/registerInfSlice';
import { selectKeyword, setKeywordList } from '../../../redux/slices/keywordSlice';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  indeterminateColor: {
    color: theme.palette.primary,
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: '50px',
  },
  linkUrl: {
    textDecoration: 'none',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function RegisterAsInfluencerForm() {
  const classes = useStyles();

  const { user, login_inf, logout_inf } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const { keywordList } = useSelector(selectKeyword);

  const dispatch = useDispatch();

  const { pages, selectedPageId, selectedPageName, loggedInFbToken, loggedInFbBusAccId, infData } =
    useSelector(selectRegisterInf);

  const [displayPages, setDisplayPages] = useState(false);

  const [pageValue, setPageValue] = useState('');

  const [keywordsArr, setKeywordsArr] = useState([]);

  const [registerObj, setRegisterObj] = useState({
    name: '',
    instagramUsername: '',
    usernameValidBool: false,
    contactNumber: '',
    keywords: [],
    email: '',
    profilePic: '',
    termsOfUserBool: false,
    privacyPolicyBool: false,
  });

  const {
    name,
    instagramUsername,
    usernameValidBool,
    contactNumber,
    keywords,
    email,
    profilePic,
    termsOfUserBool,
    privacyPolicyBool,
  } = registerObj;

  const isAllSelected = keywordsArr?.length > 0 && keywords?.length === keywordsArr.length;

  const [errorObj, setErrorObj] = useState({
    errorBool: false,
    errorMsg: '',
  });

  const temp_initialiseKeywords = useRef();

  const temp_initFormCred = useRef();

  useEffect(() => {
    temp_initialiseKeywords.current();
  }, []);

  useEffect(() => {
    temp_initFormCred.current();
  }, [user]);

  const initialiseKeywords = () => {
    if (keywordList && keywordList.length === 0) {
      db.collection('keyword')
        .doc('keywordArray')
        .onSnapshot(
          (doc) => {
            let arr = [];
            arr = [...doc.data().keywordArr];
            setKeywordsArr(arr);
            dispatch(setKeywordList(arr));
          },
          (error) => {
            enqueueSnackbar(`Error occured while fetching keywords: ${error?.message}`, { variant: 'error' });
          }
        );
    } else {
      setKeywordsArr([...keywordList]);
    }
  };

  temp_initialiseKeywords.current = initialiseKeywords;

  const initFormCred = async () => {
    if (user?.id && user?.id !== '') {
      getInfDet(user?.id);
    }
  };

  temp_initFormCred.current = initFormCred;

  const getInfDet = async (id) => {
    dispatch(setMsg('Retrieving Influencer details'));
    dispatch(setLoading(true));
    await db
      .collection('influencer')
      .doc(id)
      .get()
      .then((doc) => {
        if (doc?.exists) {
          dispatch(setLoggedInFbId(doc.data().fbId));
          dispatch(setLoggedInFbToken(doc.data().token));
          getInfPages(doc.data().fbId, doc.data().token);
          setRegisterObj({ ...registerObj, email: doc.data().email });
        } else {
          dispatch(setMsg(''));
          dispatch(setLoading(false));
          auth.signOut();
          logout_inf();
          // enqueueSnackbar('This influencer cannot be found', { variant: 'error' });
        }
      })
      .catch(() => {
        dispatch(setMsg(''));
        dispatch(setLoading(false));
        // enqueueSnackbar(`Error occured while retrieving influencer details: ${err?.message}`, { variant: 'error' });
        auth.signOut();
        logout_inf();
      });
  };

  const getInfPages = async (id, token) => {
    const url = process.env.REACT_APP_URL_PREFIX + '/' + id + '/accounts?access_token=' + token;
    await axios
      .get(url)
      .then(async (res) => {
        console.log('Inf pages: ', res);
        dispatch(setPages(res.data.data));
        setDisplayPages(true);

        dispatch(setMsg(''));
        dispatch(setLoading(false));
      })
      .catch((err) => {
        setDisplayPages(false);
        let error = err.response;
        enqueueSnackbar(`Error occured while retrieving user pages: ${error?.data?.error?.message}`, {
          variant: 'error',
        });
        dispatch(setMsg(''));
        dispatch(setLoading(false));
      });
  };

  const handleUserPageChange = (event) => {
    let valObj = JSON.parse(event.target.value);
    dispatch(setSelectedPageId(valObj.id));
    dispatch(setSelectedPageName(valObj.name));
    setPageValue(event.target.value);
  };

  const retrieveBusinessAcc = async () => {
    dispatch(setMsg('Retrieving Influencer details via business page.'));
    dispatch(setLoading(true));
    const pageId = selectedPageId;
    const igUserToken = loggedInFbToken;
    const reqBusAcc =
      process.env.REACT_APP_URL_PREFIX +
      '/' +
      pageId +
      '?fields=instagram_business_account&access_token=' +
      igUserToken;

    await axios
      .get(reqBusAcc)
      .then(async (res) => {
        let buAccId = res?.data?.instagram_business_account?.id;
        console.log('res', res);

        /**
         * check whether the page chosen to linked to the business account chosen
         * NOTE:-
         * An influencer can have multiple business acount and can choose more than one
         * We need to make sure that the business account id is the same as the res.data id before procceeding
         *  */

        // check if the pages chosen are linked to the business accounts chosen -> check if res?.data?.instagram_business_account exists
        if (buAccId !== '' && buAccId !== undefined) {
          await db
            .collection('influencer')
            .doc(user?.id)
            .set(
              {
                fbPageId: pageId,
                fbBusAccId: buAccId,
              },
              { merge: true }
            )
            .then(() => {
              dispatch(setLoggedInFbBusAccId(buAccId));
              dispatch(setLoggedInFbPageId(pageId));

              setDisplayPages(false);
              dispatch(setMsg(''));
              dispatch(setLoading(false));
            })
            .catch((err) => {
              dispatch(setMsg(''));
              dispatch(setLoading(false));
              enqueueSnackbar(`Error occured while updating influencer: ${err?.message}`, { variant: 'error' });
            });
        } else {
          dispatch(setMsg(''));
          dispatch(setLoading(false));
          enqueueSnackbar(
            `Error occured: The page you chose is not associated to your Instagram business account. Please try another page.`,
            {
              variant: 'error',
            }
          );
        }
      })
      .catch((err) => {
        dispatch(setMsg(''));
        dispatch(setLoading(false));
        enqueueSnackbar(`Error occured while fetching business account: ${err?.message}`, { variant: 'error' });
      });
  };

  const verifyUsername = async () => {
    if (instagramUsername !== '') {
      dispatch(setMsg("Retrieving Influencer's Instagram public details"));
      dispatch(setLoading(true));

      const url = `https://graph.facebook.com/${loggedInFbBusAccId}?fields=business_discovery.username(${instagramUsername}){id,ig_id,name,profile_picture_url,followers_count,media_count,biography,media{id,owner,comments_count,like_count,caption,media_product_type,media_type,media_url,permalink,timestamp}}&access_token=${loggedInFbToken}`;
      await axios
        .get(url)
        .then(async (res) => {
          if (res) {
            dispatch(setMsg('Fetching influencer data from instagram'));
            // check if data array is not empty
            if (res?.data?.business_discovery?.media?.data?.length > 0) {
              let influencerData = await calculateInfluencerData(
                res?.data.business_discovery,
                res?.data.business_discovery?.media,
                0,
                0
              );

              if (influencerData) {
                influencerData = {
                  ...influencerData,
                  status: 'valid',
                  course_status: 'none',
                  access: false,
                  role: 'influencer',
                };

                dispatch(setMsg('Converting profile picture url to actual image'));
                // save image url as an actual image and store in firebase cloud storage
                let s_profile_url = res?.data?.business_discovery?.profile_picture_url;
                console.log('Profile Pic url: ', s_profile_url);
                var url = s_profile_url;
                // First, download the file:
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = () => {
                  var blob = xhr.response;
                  // Define where to store the picture:
                  var picRef = firebase.storage().ref(`profile_pictures/${email}/profilePhoto`);

                  // Store the picture:
                  picRef.put(blob).then(() => {
                    console.log('Picture uploaded!');
                    dispatch(setMsg('Picture saved.'));

                    // Now get image from storage and display in div...
                    picRef
                      .getDownloadURL()
                      .then((pic) => {
                        var userspic = pic;
                        // document.getElementById('picTestImage').src = userspic;

                        influencerData = {
                          ...influencerData,
                          profilePicUrl: userspic,
                          info: { ...influencerData?.info, profilePicUrl: userspic },
                        };

                        setRegisterObj({
                          ...registerObj,
                          profilePic: userspic, // res?.data?.business_discovery?.profile_picture_url,
                          usernameValidBool: true,
                        });

                        dispatch(setInfData(influencerData));
                        dispatch(setMsg(''));
                        dispatch(setLoading(false));
                      })
                      .catch((error) => {
                        console.log('There was an error: ' + error);
                        dispatch(setMsg(''));
                        dispatch(setLoading(false));
                      });
                  });
                };

                xhr.open('GET', url);
                xhr.send();
                /* await getBlob(s_profile_url)
                  .then(function (blob) {
                    console.log('Blob: ', blob);
                    var picRef = firebase.storage().ref().child(`profile_pictures/${email}/profilePhoto`);
                    return picRef.put(blob);
                  })
                  .then(function (snapshot) {
                    console.log('Profile Pic download URl: ', snapshot.downloadURL);
                    setRegisterObj({
                      ...registerObj,
                      profilePic: snapshot.downloadURL, // res?.data?.business_discovery?.profile_picture_url,
                      usernameValidBool: true,
                    });
                    // return snapshot.downloadURL;
                  })
                  .catch(function (e) {
                    enqueueSnackbar(
                      `Error occured while storing influencer profile pic (url to actual image): ${e?.message}`,
                      { variant: 'error' }
                    );
                  }); */

                // dispatch(setInfData(influencerData));

                // dispatch(setMsg(''));
                // dispatch(setLoading(false));
              }
            } else {
              enqueueSnackbar(
                'Api retrieve an empty array. Meaning that there are no posts retrieved for that influencer.',
                { variant: 'error' }
              );
              dispatch(setMsg(''));
              dispatch(setLoading(false));
            }
          } else {
            enqueueSnackbar('Api got media as undefined. There is a problem with that influencer.', {
              variant: 'error',
            });
            dispatch(setMsg(''));
            dispatch(setLoading(false));
          }
        })
        .catch((err) => {
          enqueueSnackbar(
            `Error fetching Username Instagram Data, please try again with the valid username: ${err.message}`,
            { variant: 'error' }
          );
          dispatch(setMsg(''));
          dispatch(setLoading(false));
        });
    }
  };

  const register = async () => {
    dispatch(setLoading(true));
    if (!termsOfUserBool) {
      setErrorObj({
        errorBool: true,
        errorMsg: 'You should agree by the Terms of Use',
      });
      dispatch(setLoading(false));
    } else if (!privacyPolicyBool) {
      setErrorObj({
        errorBool: true,
        errorMsg: 'You should agree by the Privacy Policy',
      });
      dispatch(setLoading(false));
    } else if (name === '' || instagramUsername === '' || contactNumber === '' || email === '' || profilePic === '') {
      setErrorObj({
        errorBool: true,
        errorMsg: 'Input must not be blank',
      });
      dispatch(setLoading(false));
    } else {
      let userData = {
        ...registerObj,
        ...infData,
        pendingReg: false,
        role: 'influencer',
      };
      db.collection('influencer')
        .doc(user?.id)
        .set(
          {
            ...userData,
          },
          { merge: true }
        )
        .then(async () => {
          const sendEmail = functions.httpsCallable('sendEmail');
          let adminEmailsArr = process.env.REACT_APP_ADMIN_EMAILS.split(',');
          let o_email = {
            //email to admin
            to: adminEmailsArr,
            adminEmail: process.env.REACT_APP_SENDGRID_ADMIN_EMAIL,
            emailId: process.env.REACT_APP_INF_REGISTER_ADMIN_EMAIL,
            attachment: false,
            returnMsg: 'Email sent to Administrators',
            emailParams: {
              subject: `The influencer ${name} registered to Konektwa.`,
              influencerName: name,
            },
          };

          await sendEmail({ email: o_email })
            .then(async () => {
              dispatch(setLoading(false));
              setErrorObj({
                errorBool: false,
                errorMsg: '',
              });
              login_inf(user?.id, userData, loggedInFbToken, 'influencer', false); // id, data, token, role
              enqueueSnackbar(
                'You have been registered successfully. Our team will check your details before giving you the access to the website. We will notify you via email. Thank you.',
                { variant: 'success' }
              );
            })
            .catch((err) => {
              enqueueSnackbar(`Error downloading pdf: ${err?.message}`, { variant: 'error' });
              dispatch(setLoading(false));
            });
        })
        .catch((err) => {
          setErrorObj({
            errorBool: false,
            errorMsg: '',
          });
          enqueueSnackbar(`Error occured while registering your data: ${err?.message}`, { variant: 'error' });
        });
    }
  };

  const handleChangeMultipleSelect = (event) => {
    const { value } = event.target;
    if (value[value.length - 1] === 'all') {
      setRegisterObj({ ...registerObj, keywords: keywords?.length === keywordsArr?.length ? [] : keywordsArr });
    } else {
      setRegisterObj({ ...registerObj, keywords: value });
    }
  };

  return (
    <>
      <Stack spacing={3} sx={{ display: displayPages ? '' : 'none' }}>
        <Typography>Choose the page to which the chosen Instagram business account is connected to.</Typography>
        <br />
        <Alert severity="info">
          Note that you must choose only one business account. In case you have chosen multiple in the previous Facebook
          permission configurations, just log out clicking in the profile button on the top right of the screen and
          login again then choose 'edit settings'.
        </Alert>
        <br />
        <Alert severity="info">
          Note that you must choose the page where you will post all Konektwa Advertising posts.
        </Alert>
        <br />
        <RadioGroup
          aria-label="User Pages"
          name="controlled-radio-buttons-group"
          value={pageValue}
          onChange={(e) => handleUserPageChange(e)}
        >
          {pages?.map((page, index) => (
            <FormControlLabel key={index} value={JSON.stringify(page)} control={<Radio />} label={page.name} />
          ))}
        </RadioGroup>

        <Stack direction="row" justifyContent={'flex-end'} spacing={2}>
          <Button
            onClick={() => retrieveBusinessAcc()}
            disabled={!selectedPageId || !selectedPageName || selectedPageId === '' || selectedPageName === ''}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        </Stack>
      </Stack>

      <Stack spacing={3} sx={{ display: !displayPages ? '' : 'none' }}>
        {errorObj?.errorBool && <Alert severity="error">{errorObj?.errorMsg}</Alert>}

        <div
          align="center"
          style={{
            display: usernameValidBool ? '' : 'none',
            position: 'relative',
            paddingBottom: '1em',
          }}
        >
          <Avatar alt="Profile Pic" src={profilePic} sx={{ height: '75px', width: '75px' }} />
        </div>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <TextField
            autoFocus
            variant="outlined"
            required
            fullWidth
            id="instagramUsername"
            name="instagramUsername"
            type="text"
            value={instagramUsername}
            label="Instagram username"
            onChange={(event) =>
              setRegisterObj({
                ...registerObj,
                instagramUsername: event.target.value,
              })
            }
          />
          <Button
            variant="contained"
            className="btn"
            color="primary"
            onClick={() => verifyUsername()}
            disabled={!instagramUsername || instagramUsername === ''}
          >
            Verify
          </Button>
        </Stack>

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Full name"
          name="name"
          type="text"
          value={name}
          onChange={(event) =>
            setRegisterObj({
              ...registerObj,
              name: event.target.value,
            })
          }
          disabled={!usernameValidBool ? true : false}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="contactNumber"
          label="Contact Number"
          name="contactNumber"
          type="text"
          value={contactNumber}
          disabled={!usernameValidBool ? true : false}
          onChange={(event) =>
            setRegisterObj({
              ...registerObj,
              contactNumber: event.target.value,
            })
          }
        />
        <FormControl fullWidth margin="normal" variant="outlined" size="medium" required>
          <InputLabel id="mutiple-select-label" required>
            Keywords
          </InputLabel>

          <Select
            label="Keywords"
            labelId="mutiple-select-label"
            multiple
            value={keywords}
            onChange={handleChangeMultipleSelect}
            renderValue={(keywords) => keywords.join(', ')}
            MenuProps={MenuProps}
            disabled={!usernameValidBool ? true : false}
          >
            <MenuItem
              value="all"
              classes={{
                root: isAllSelected ? classes.selectedAll : '',
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{
                    indeterminate: classes.indeterminateColor,
                  }}
                  checked={isAllSelected}
                  indeterminate={keywords?.length > 0 && keywords?.length < keywordsArr?.length}
                />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
            </MenuItem>
            {keywordsArr?.map((option) => (
              <MenuItem key={option} value={option}>
                <ListItemIcon>
                  <Checkbox checked={keywords?.indexOf(option) > -1} />
                </ListItemIcon>
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          type="text"
          value={email}
          disabled
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <FormControlLabel
            label=""
            control={
              <Checkbox
                name="termsOfUse"
                checked={termsOfUserBool}
                onChange={(e) =>
                  setRegisterObj({
                    ...registerObj,
                    termsOfUserBool: e.target.checked,
                  })
                }
                color="primary"
                disabled={!usernameValidBool ? true : false}
              />
            }
          />
          <div style={{ cursor: 'pointer' }}>
            <Link to="/termsconditions" target="_blank" rel="noopener noreferrer">
              I accept the Influencer Terms of Use
            </Link>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <FormControlLabel
            label=""
            control={
              <Checkbox
                name="privacyAndPolicy"
                checked={privacyPolicyBool}
                onChange={(e) =>
                  setRegisterObj({
                    ...registerObj,
                    privacyPolicyBool: e.target.checked,
                  })
                }
                color="primary"
                disabled={!usernameValidBool ? true : false}
              />
            }
          />
          <div style={{ cursor: 'pointer' }} /*onClick={() => setOpen(true)}*/>
            <Link to="/privacypolicy" target="_blank" rel="noopener noreferrer">
              I accept the Privacy and Policy
            </Link>
          </div>
        </div>

        <div align="center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            className="btn"
            onClick={(e) => register(e)}
            fullWidth
            disabled={
              name === '' ||
              instagramUsername === '' ||
              contactNumber === '' ||
              email === '' ||
              profilePic === '' ||
              !termsOfUserBool ||
              !privacyPolicyBool
            }
          >
            Register
          </Button>
        </div>
      </Stack>
    </>
  );
}
