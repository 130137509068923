import { createSlice } from '@reduxjs/toolkit';

export const planningSlice = createSlice({
  name: 'planning',
  initialState: {
    originalAllPosts: [],
    modifiedAllPosts: [],
    selectedCamp: {},
    eventModifyId: {},
    originalSelectedCampPosts: [],
    modifiedSelectedCampPosts: [],
    selectedEvent: {},

    isOpenModal: false,
    selectedEventId: null,
  },
  reducers: {
    setOriginalAllPosts: (state, action) => {
      state.originalAllPosts = action.payload;
    },
    setModifiedAllPosts: (state, action) => {
      state.modifiedAllPosts = action.payload;
    },
    setSelectedCamp: (state, action) => {
      state.selectedCamp = action.payload;
    },
    setEventModifyId: (state, action) => {
      state.eventModifyId = action.payload;
    },
    setOriginalSelectedCampPosts: (state, action) => {
      state.originalSelectedCampPosts = action.payload;
    },
    setModifiedSelectedCampPosts: (state, action) => {
      state.modifiedSelectedCampPosts = action.payload;
    },
    setSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },
    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },
    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
    },
    resetPlan: (state) => {
      state.originalAllPosts = [];
      state.modifiedAllPosts = [];
      state.selectedCamp = {};
      state.eventModifyId = {};
      state.originalSelectedCampPosts = [];
      state.modifiedSelectedCampPosts = [];
      state.selectedEvent = {};
      state.isOpenModal = false;
      state.selectedEventId = null;
    },
  },
});

export const {
  setOriginalAllPosts,
  setModifiedAllPosts,
  setSelectedCamp,
  setEventModifyId,
  setOriginalSelectedCampPosts,
  setModifiedSelectedCampPosts,
  setSelectedEvent,
  selectEvent,
  openModal,
  closeModal,
  resetPlan,
} = planningSlice.actions;

export const selectPlanning = (state) => state.planning;

export default planningSlice.reducer;
