import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Alert, Button, CardHeader, Card, Typography } from '@mui/material';
import useIsMountedRef from '../../../../../hooks/useIsMountedRef';

// redux
import { useDispatch, useSelector } from '../../../../../redux/store';
import { selectRecruit, setObjective, setFormActiveStep } from '../../../../../redux/slices/recruitSlice';
import { FormProvider, RHFTextField } from '../../../../../components/hook-form';
import { LoadingButton } from '@mui/lab';
import useResponsive from '../../../../../hooks/useResponsive';
import { useRef } from 'react';
import { styled } from '@mui/styles';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

function Slide6({ current, index, handlePreviousClick }) {
  const dispatch = useDispatch();
  const isDesktop = useResponsive('up', 'md');

  const slideRef = useRef(null);

  let classNames = 'slide';

  if (current === index) classNames += ' slide--current';
  else if (current - 1 === index) classNames += ' slide--previous';
  else if (current + 1 === index) classNames += ' slide--next';

  const isMountedRef = useIsMountedRef();
  const recruitSlice = useSelector(selectRecruit);
  const { objective, formActiveStep } = recruitSlice;

  const RegistersSchema = Yup.object().shape({
    objective: Yup.string(),
  });

  const defaultValues = {
    objective: objective !== '' ? objective : '',
  };

  const methods = useForm({
    resolver: yupResolver(RegistersSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      dispatch(setObjective(data.objective));
      // setStep((step) => step + 1);
      dispatch(setFormActiveStep(formActiveStep + 1));
    } catch (error) {
      console.error(error);
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', error);
      }
    }
  };

  return (
    <>
      <li
        ref={slideRef}
        className={classNames}
        style={{ width: isDesktop ? '50vmin' : '75vmin' }}
        // onClick={handleSlideOnClick}
        // onKeyDown={handleSlideOnClick}
        // onMouseMove={handleMouseMove}
        // onMouseLeave={handleMouseLeave}
      >
        <Card sx={{ p: 3, /* mb: 3, */ minHeight: '450px' }}>
          <CardHeader
            title={<LabelStyle>1) e. What objectives do you want to set for this campaign (optional)</LabelStyle>}
            sx={{ mb: 3 }}
          />
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
              {/* <RHFEditor simple name="objective" /> */}
              <RHFTextField name="objective" multiline rows={7} disabled={current !== index} />

              <Stack spacing={3} direction="row">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    // setStep((step) => step - 1);
                    handlePreviousClick();
                  }}
                  size="large"
                  fullWidth
                  disabled={current !== index}
                >
                  Back
                </Button>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={current !== index}
                >
                  Proceed to next section
                </LoadingButton>
              </Stack>
            </Stack>
          </FormProvider>
        </Card>
      </li>
    </>
  );
}

// ----------------------------------------------------------------------

Slide6.propTypes = {
  current: PropTypes.number,
  index: PropTypes.number,
  handlePreviousClick: PropTypes.func,
};

export default Slide6;
