import { createSlice } from '@reduxjs/toolkit';

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    msg: '',
    loading: false,
    toggleNight: 'light',
  },
  reducers: {
    setMsg: (state, action) => {
      state.msg = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setToggleNight: (state, action) => {
      state.toggleNight = action.payload;
    },
    resetGlobal: (state) => {
      state.msg = '';
      state.loading = false;
    },
  },
});

export const { setMsg, setLoading, setToggleNight, resetGlobal } = globalSlice.actions;

export const selectGlobal = (state) => state.global;

export default globalSlice.reducer;
