import { createSlice } from '@reduxjs/toolkit';

export const planningCaptionSlice = createSlice({
  name: 'planningCaption',
  initialState: {
    selectedCampCaptionObj: {},
    showPlanCaption: false,
  },
  reducers: {
    setSelectedCampCaptionObj: (state, action) => {
      state.selectedCampCaptionObj = action.payload;
    },
    setShowPlanCaption: (state, action) => {
      state.showPlanCaption = action.payload;
    },
    resetPlanCap: (state) => {
      state.selectedCampCaptionObj = {};
      state.showPlanCaption = false;
    },
  },
});

export const { setSelectedCampCaptionObj, setShowPlanCaption, resetPlanCap } = planningCaptionSlice.actions;

export const selectPlanningCaption = (state) => state.planningCaption;

export default planningCaptionSlice.reducer;
