import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
// @types
import { NAVBAR } from '../../../../../../config';
// components
import Iconify from '../../../../../../components/Iconify';
import Scrollbar from '../../../../../../components/Scrollbar';
import { useDispatch } from 'react-redux';
import { setFilterInfluencers } from '../../../../../../redux/slices/InfluencerFiltersSlice';

// ----------------------------------------------------------------------

export const SORT_BY_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High-Low' },
  { value: 'priceAsc', label: 'Price: Low-High' },
];

export const FILTER_GENDER_OPTIONS = ['Men', 'Women', 'Kids'];

export const FILTER_CATEGORY_OPTIONS = ['All', 'Shose', 'Apparel', 'Accessories'];

export const FILTER_RATING_OPTIONS = ['up4Star', 'up3Star', 'up2Star', 'up1Star'];

export const FILTER_PRICE_OPTIONS = [
  { value: 'below', label: 'Below $25' },
  { value: 'between', label: 'Between $25 - $75' },
  { value: 'above', label: 'Above $75' },
];

export const FILTER_FOLLOWERS_OPTIONS = [
  { value: 'below', label: 'Below 15k' },
  { value: 'between', label: 'Between 15k - 30k' },
  { value: 'above', label: 'Above 30k' },
];

export const FILTER_ENGRATE_OPTIONS = [
  { value: 'below', label: 'Below 25%' },
  { value: 'between', label: 'Between 25% - 75%' },
  { value: 'above', label: 'Above 75%' },
];

export const FILTER_COLOR_OPTIONS = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107',
];

// ----------------------------------------------------------------------

InfluencerFilterSidebar.propTypes = {
  filters: PropTypes.object,
  isOpen: PropTypes.bool,
  onResetAll: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

// ----------------------------------------------------------------------

export default function InfluencerFilterSidebar({ filters, isOpen, onResetAll, onOpen, onClose }) {
  const dispatch = useDispatch();

  const handleRadioChange = (event, type) => {
    let newFilters = { ...filters };
    if (type === 'fol') {
      newFilters = { ...newFilters, followerBase: event.target.value };
      dispatch(setFilterInfluencers(newFilters));
    } else if (type === 'eng') {
      newFilters = { ...newFilters, engRate: event.target.value };
      dispatch(setFilterInfluencers(newFilters));
    }
  };

  return (
    <>
      <Button disableRipple color="inherit" endIcon={<Iconify icon={'ic:round-filter-list'} />} onClick={onOpen}>
        Filters
      </Button>

      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: { width: NAVBAR.BASE_WIDTH },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onClose}>
            <Iconify icon={'eva:close-fill'} width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1}>
              <Typography variant="subtitle1">Followers</Typography>

              <RadioGroup
                aria-labelledby="followers radio group"
                name="followers-buttons-group"
                onChange={(e) => handleRadioChange(e, 'fol')}
              >
                {FILTER_FOLLOWERS_OPTIONS?.map((fol, index) => (
                  <FormControlLabel key={index} value={fol?.value} control={<Radio />} label={fol?.label} />
                ))}
              </RadioGroup>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="subtitle1">Engagement rate</Typography>

              <RadioGroup
                aria-labelledby="engagements radio group"
                name="engagements-buttons-group"
                onChange={(e) => handleRadioChange(e, 'eng')}
              >
                {FILTER_ENGRATE_OPTIONS?.map((eng, index) => (
                  <FormControlLabel key={index} value={eng?.value} control={<Radio />} label={eng?.label} />
                ))}
              </RadioGroup>
            </Stack>
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={onResetAll}
            startIcon={<Iconify icon={'ic:round-clear-all'} />}
          >
            Clear All
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
