import PropTypes from 'prop-types';
import { Fragment, useEffect, useRef, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Container,
  Card,
  Stack,
  Button,
  Box,
  ButtonBase,
  Avatar,
  Link,
  Grid,
  CardHeader,
  CardContent,
  Divider,
  Dialog,
} from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import useSettings from '../../../../../../hooks/useSettings';
import CurrencyFormat from 'react-currency-format';

import { useDispatch, useSelector } from 'react-redux';
import {
  setInfluArr,
  selectRecruit,
  setTotalAmtObj,
  setTotalAmtObjAddNewInflu,
  setFormActiveStep,
  setCompanyName,
  setCampaignName,
  setStartingDate,
  setEndingDate,
  setBrandPhilo,
  setProductToPromo,
  setUsp,
  setAgeRange,
  setMarkSitua,
  setObjective,
  setOriginalInfluArr,
  setInfluArrAddNewInflu,
  setFormNewInfActiveStep,
} from '../../../../../../redux/slices/recruitSlice';
import Scrollbar from '../../../../../../components/Scrollbar';
import ViewProfilePic from '../../../CampaignDetails/ViewProfilePic';
import { useSnackbar } from 'notistack';
import { setLoading, setMsg } from '../../../../../../redux/slices/globalSlice';
import useAuth from '../../../../../../hooks/useAuth';
import db, { functions } from '../../../../../../firebase';
import firebase from 'firebase/compat';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from '../../../../../../routes/paths';
import { selectClient } from '../../../../../../redux/slices/clientSlice';
import moment from 'moment';

const NoMaxWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const CustomTableCellNumber = ({ inf, name, onChange }) => (
  <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
    <TextField
      value={inf[name]}
      name={name}
      type="number"
      onChange={(e) => onChange(e, inf, name)}
      size="small"
      variant="outlined"
      fullWidth
      style={{ minWidth: '50px' }}
    />
  </TableCell>
);

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

CustomTableCellNumber.propTypes = {
  inf: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

// ----------------------------------------------------------------------

PricingDetails.propTypes = {
  setOpenAddNew: PropTypes.func,
};

// ----------------------------------------------------------------------

function PricingDetails({ setOpenAddNew }) {
  const dispatch = useDispatch();

  const { user } = useAuth();

  const recruitSlice = useSelector(selectRecruit);

  const {
    influArr,
    totalAmtObj,
    totalAmtObjAddNewInflu,
    type,
    influArrAddNewInflu,
    formActiveStep,
    formNewInfActiveStep,
    docUploadFile,
  } = recruitSlice;

  const { campDetObj, aCampOpt } = useSelector(selectClient);

  const { enqueueSnackbar } = useSnackbar();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const temp_total = useRef();

  const [openPhoto, setOpenPhoto] = useState(false);

  const [picUrl, setPicUrl] = useState('');

  useEffect(() => {
    temp_total.current();
  }, [type]);

  const _ue_init_total = () => {
    if (influArr && influArrAddNewInflu) {
      let arrayOriginal = [];
      if (type === 'add_new') {
        arrayOriginal = [...influArrAddNewInflu];
      } else if (type === 'recruit') {
        arrayOriginal = [...influArr];
      }

      if (arrayOriginal.length > 0) {
        let storyAmtCal = 0;
        let gridAmtCal = 0;
        let igtvAmtCal = 0;
        let totalAmtCal = 0;
        arrayOriginal.forEach((inf) => {
          if (inf?.data?.selected) {
            //apply change
            storyAmtCal = Number(storyAmtCal) + Number(inf.storyCount) * 1150; //999; // Number(newRow.data.postRate); // * 2;
            gridAmtCal = Number(gridAmtCal) + Number(inf.gridCount) * Number(inf.data.postRate); // * 2;
            igtvAmtCal = Number(igtvAmtCal) + Number(inf.igtvCount) * Number(inf.data.postRate); // * 2;
          }
        });
        totalAmtCal = storyAmtCal + gridAmtCal + igtvAmtCal;

        if (type === 'add_new') {
          dispatch(
            setTotalAmtObjAddNewInflu({
              storyAmt: storyAmtCal,
              gridAmt: gridAmtCal,
              igtvAmt: igtvAmtCal,
              total: totalAmtCal,
            })
          );
        } else if (type === 'recruit') {
          dispatch(
            setTotalAmtObj({
              storyAmt: storyAmtCal,
              gridAmt: gridAmtCal,
              igtvAmt: igtvAmtCal,
              total: totalAmtCal,
            })
          );
        }
      } else {
        if (type === 'add_new') {
          dispatch(
            setTotalAmtObjAddNewInflu({
              storyAmt: 0,
              gridAmt: 0,
              igtvAmt: 0,
              total: 0,
            })
          );
        } else if (type === 'recruit') {
          dispatch(
            setTotalAmtObj({
              storyAmt: 0,
              gridAmt: 0,
              igtvAmt: 0,
              total: 0,
            })
          );
        }
      }
    }
  };

  temp_total.current = _ue_init_total;

  const onChange = (e, row) => {
    var value = e?.target?.value || '';

    if (Math.round(value) >= 0) {
      value = Math.round(value);
      const name = e?.target?.name || '';
      const { id } = row;
      let storyAmtCal = 0;
      let gridAmtCal = 0;
      let igtvAmtCal = 0;
      let totalAmtCal = 0;

      let arrayOriginal = [];
      if (type === 'add_new') {
        arrayOriginal = [...influArrAddNewInflu];
      } else if (type === 'recruit') {
        arrayOriginal = [...influArr];
      }

      const newRows = arrayOriginal.map((row) => {
        if (row.id === id) {
          //apply change
          let newRow = { ...row, [name]: value };

          if (newRow.storyCount > 0) {
            storyAmtCal = Number(storyAmtCal) + Number(newRow.storyCount) * 1150; // 999; // Number(newRow.data.postRate); // * 2;
          }

          // gridAmtCal = Number(gridAmtCal) + Number(newRow.gridCount) * Number(newRow.data.postRate); // * 2;
          /**
           * rule for grid
           * take postRate for first grid
           * take postRate - 500 for second grid
           * take postRate - 1000 for the other grids
           */
          if (newRow.gridCount > 0) {
            if (newRow.gridCount === 1) {
              gridAmtCal = Number(gridAmtCal) + Number(newRow.data.postRate);
            } else if (newRow.gridCount === 2) {
              let i_second_grid_price = Number(newRow.data.postRate) - 500;
              gridAmtCal = Number(gridAmtCal) + Number(newRow.data.postRate) + i_second_grid_price;
            } else if (newRow.gridCount > 2) {
              let i_other_count = 0;
              let i_second_grid_price = Number(newRow.data.postRate) - 500;
              let i_other_grid_price = i_second_grid_price - 500;
              let i_other_total = 0;
              i_other_count = newRow.gridCount - 2;
              i_other_total = i_other_count * i_other_grid_price;
              gridAmtCal = Number(gridAmtCal) + Number(newRow.data.postRate) + i_second_grid_price; // price for first and second grids
              gridAmtCal = Number(gridAmtCal) + i_other_total; // price for the remaining grids
            }
          }

          // igtvAmtCal = Number(igtvAmtCal) + Number(newRow.igtvCount) * Number(newRow.data.postRate); // * 2;
          return newRow;
        } else if (row?.data?.selected) {
          storyAmtCal = Number(storyAmtCal) + Number(row.storyCount) * 1150; // 999;
          // gridAmtCal = Number(gridAmtCal) + Number(row.gridCount) * Number(row.data.postRate);
          /**
           * rule for grid
           * take postRate for first grid
           * take postRate - 500 for second grid
           * take postRate - 1000 for the other grids
           */
          if (row.gridCount > 0) {
            if (row.gridCount === 1) {
              gridAmtCal = Number(gridAmtCal) + Number(row.data.postRate);
            } else if (row.gridCount === 2) {
              let i_second_grid_price = Number(row.data.postRate) - 500;
              gridAmtCal = Number(gridAmtCal) + Number(row.data.postRate) + i_second_grid_price;
            } else if (row.gridCount > 2) {
              let i_other_count = 0;
              let i_second_grid_price = Number(row.data.postRate) - 500;
              let i_other_grid_price = i_second_grid_price - 500;
              let i_other_total = 0;
              i_other_count = row.gridCount - 2;
              i_other_total = i_other_count * i_other_grid_price;
              gridAmtCal = Number(gridAmtCal) + Number(row.data.postRate) + i_second_grid_price; // price for first and second grids
              gridAmtCal = Number(gridAmtCal) + i_other_total; // price for the remaining grids
            }
          }
          // igtvAmtCal = Number(igtvAmtCal) + Number(row.igtvCount) * Number(row.data.postRate);
        }
        return row;
      });

      if (storyAmtCal) {
        totalAmtCal = totalAmtCal + storyAmtCal;
      }
      if (gridAmtCal) {
        totalAmtCal = totalAmtCal + gridAmtCal;
      }
      if (igtvAmtCal) {
        totalAmtCal = totalAmtCal + igtvAmtCal;
      }

      if (type === 'add_new') {
        dispatch(setInfluArrAddNewInflu(newRows));
        dispatch(
          setTotalAmtObjAddNewInflu({
            storyAmt: storyAmtCal,
            gridAmt: gridAmtCal,
            igtvAmt: igtvAmtCal,
            total: totalAmtCal,
          })
        );
      } else if (type === 'recruit') {
        dispatch(setInfluArr(newRows));
        dispatch(
          setTotalAmtObj({
            storyAmt: storyAmtCal,
            gridAmt: gridAmtCal,
            igtvAmt: igtvAmtCal,
            total: totalAmtCal,
          })
        );
      }
    }
  };

  const handleClose = () => {
    setOpenPhoto(false);
  };

  const confirm = async () => {
    if ((type === 'recruit' && totalAmtObj?.total <= 0) || (type === 'add_new' && totalAmtObjAddNewInflu?.total <= 0)) {
      enqueueSnackbar(
        'Please give the number of Stories/Grid of each influencer for the campaign before proceeding to Quotation.',
        { variant: 'error' }
      );
    } else {
      dispatch(setMsg('Process campaign details')); // loading message
      dispatch(setLoading(true));
      //create quotation calculation for the pending campaign
      let inFluencerChosenArr = [];
      let quotePriceListArr = [];
      // Get a new write batch
      // var batch = db.batch();

      /**
       * prepare quotation list array with all the chosen influencers
       * calculation of remuneration cost
       * prepare objects to display in quotation
       * return quotePriceListArr used in quotation
       * return inFluencerChosenArr - containing object including remuneration fees - influencer objects
       */
      let o_req_obj = await _prep_aInflu_obj(type, influArr, influArrAddNewInflu);

      // check whether we got influencers and quotation objects array
      if (o_req_obj?.quotePriceListArr?.length > 0 && o_req_obj?.inFluencerChosenArr?.length > 0) {
        inFluencerChosenArr = o_req_obj?.inFluencerChosenArr;
        quotePriceListArr = o_req_obj?.quotePriceListArr;

        if (type === 'add_new') {
          /**
           * if type -> add_new
           * -> get quotation price list already done upwards
           * -> Merge the quotation array data with the previous one in db
           * -> Merge actual influencer chosen with the newly selected influencers
           * -> Calculate new total - vat - subtotal - gran total
           * -> Prepare new campaign object
           * -> Get message status to update a_camp
           * -> Update campaign doc + influencer doc
           * -> add campaign to the chosen influencers
           * -> use of batch for the updates
           * -> send email to admin, client and influencers
           */

          // 1- Merge the quotation array data with the previous one in db
          let newQuotePriceListArr = quotePriceListArr.concat(campDetObj?.data?.quotation?.quotePriceListArr);

          // 2- Merge actual influencer chosen with the newly selected influencers
          let aNewInfluChosen = [...inFluencerChosenArr];
          if (campDetObj?.data?.aInfluChosen?.length > 0) {
            campDetObj?.data?.aInfluChosen?.forEach((inf) => {
              aNewInfluChosen.push({
                ...inf,
              });
            });
          }

          dispatch(setMsg('Calculate new totals')); // loading message
          // 3- Calculate new total - vat - subtotal - gran total
          let o_total = await _calculateTotals(totalAmtObjAddNewInflu, campDetObj?.data?.oTotalAmt);

          if (o_total) {
            // 4- Prepare new campaign object
            let newCampaignObj = {
              aInfluChosen: aNewInfluChosen,
              oTotalAmt: o_total?.oNewTotalAmt,
              quotation: {
                ...campDetObj.data.quotation,
                aInfluChosen: aNewInfluChosen,
                quotePriceListArr: newQuotePriceListArr,
                estimateDate: '',
                expireDate: '',
                grandTotalCal: o_total?.newGrandTotal,
                status: 'pending',
                subTotal: o_total?.finalTotalAmt,
                uid: '',
                vat: o_total?.newVat,
              },
            };

            // 5- get message status to update a_camp
            // let campStatus = await getCampStatusMsg('pending', aNewInfluChosen);

            // 6- check if message is fetched
            // if (campStatus) {
            /**
             *  update array of campaigns - a_camp
             * return updated array of campaigns
             * */
            // let a_new_camp = await _core_update_a_camp_msg(aCampOpt, campIdChosen, campStatus, aNewInfluChosen);

            // if (a_new_camp.length > 0) {
            // 7- Update campaign doc + influencer doc

            // let o_aCamp_ref = db.collection('client').doc(user?.id).collection('campaign').doc('a_camp');

            // create campaign doc ref
            // var o_camp_ref = db.collection('client').doc(user?.id).collection('campaign').doc(campDetObj?.id);

            // update a_camp doc
            // batch.set(o_aCamp_ref, { a_camp: a_new_camp }, { merge: true });

            // add camp changes to batch
            // batch.set(o_camp_ref, { ...newCampaignObj }, { merge: true });

            await db
              .collection('client')
              .doc(user?.id)
              .collection('campaign')
              .doc(campDetObj?.id)
              .set({ ...newCampaignObj }, { merge: true })
              .then(() => {
                dispatch(setMsg('Updating campaign doc and influencers docs')); // loading message
                // loop through all influencers and add them to batch
                inFluencerChosenArr.forEach(async (inf) => {
                  var o_inf_ref = db.collection('influencer').doc(inf.id).collection('campaign').doc(campDetObj?.id);

                  // add to batch
                  o_inf_ref.set(
                    {
                      oCampDet: campDetObj.data.oCampDet,
                      aInfluChosen: inFluencerChosenArr,
                      approvedToWork: 'not_yet_approved',
                      rejected: false,
                      clientId: user?.id,
                      clientEmail: user?.email,
                      campaignId: campDetObj.id,
                      storyCount: inf.storyCount,
                      gridCount: inf.gridCount,
                      igtvCount: inf.igtvCount,
                      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    },
                    { merge: true }
                  );
                });

                dispatch(setMsg('Sendinf emails to client, admins and influencers')); // loading message
                // 9- send email
                _sendEmailsAddNew(inFluencerChosenArr, campDetObj?.data?.oCampDet);
              })
              .catch((error) => {
                enqueueSnackbar(`Error batch update for campaign or updating influencers: ${error?.message}`);
                dispatch(setMsg('')); // clear loading message
                dispatch(setLoading(false));
              });

            // 8-  Commit the batch
            /* batch
              .commit()
              .then(() => {
                // loop through all influencers and add them to batch
                inFluencerChosenArr.forEach(async (inf) => {
                  var o_inf_ref = db.collection('influencer').doc(inf.id).collection('campaign').doc(campDetObj?.id);

                  // add to batch
                  o_inf_ref.set({
                    oCampDet: campDetObj.data.oCampDet,
                    approvedToWork: 'not_yet_approved',
                    clientId: user?.id,
                    clientEmail: user?.email,
                    campaignId: campDetObj.id,
                    storyCount: inf.storyCount,
                    gridCount: inf.gridCount,
                    igtvCount: inf.igtvCount,
                    rejected: false,
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                  });
                });

                dispatch(setMsg('Sendinf emails to client, admins and influencers')); // loading message
                // 9- send email
                _sendEmailsAddNew(inFluencerChosenArr, campDetObj?.data?.oCampDet);
              })
              .catch((error) => {
                enqueueSnackbar(`Error batch update for campaign or updating influencers: ${error?.message}`);
                dispatch(setMsg('')); // clear loading message
                dispatch(setLoading(false));
              }); */
            // }
            /* } else {
              enqueueSnackbar('Error fetching campaign status', { variant: 'error' });
              dispatch(setMsg('')); // clear loading message
              dispatch(setLoading(false));
            } */
          }
        } else if (type === 'recruit') {
          /**
           * if type -> recruit
           * -> create new objects for the chosen influencer already done upwards
           * -> create new object for campaign details
           * -> add new doc in campaign and retrieve campaign id
           * -> update all the chosen influencers with new campaign id
           * -> send email to admin, client and influencers
           */

          // 1- format datetimes
          let o_dates = await _formatDate(recruitSlice);

          if (o_dates) {
            // 2- prepare new camp doc

            let oCampDet = {
              companyName: recruitSlice?.companyName || '',
              campaignName: recruitSlice.campaignName || '',
              startingDate: o_dates?.strInDate || '', //recruitSlice.startingDate,
              endingDate: o_dates?.endInDate || '', //recruitSlice.endingDate,
              brandPhilo: recruitSlice?.brandPhilo || '',
              productToPromo: recruitSlice?.productToPromo || '',
              usp: recruitSlice?.usp || '',
              ageRange: recruitSlice?.ageRange || '',
              markSitua: recruitSlice?.markSitua || '',
              objective: recruitSlice?.objective || '',
            };

            dispatch(setMsg('Create campaign')); // loading message
            // 3- create new campaign
            await db
              .collection('client')
              .doc(user?.id)
              .collection('campaign')
              .add({
                oCampDet: oCampDet,
                aInfluChosen: inFluencerChosenArr,
                oTotalAmt: recruitSlice?.totalAmtObj, // object containing  { total story amt, total grid amt, total igtv amt}
                statusCode: 'pending',
                msg: 'Campaign created, waiting for influencers to respond.',
                msgTyp: 'info',
                quotation: {
                  quotePriceListArr: quotePriceListArr,
                  status: 'pending',
                },
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              })
              .then(async (response) => {
                /**
                 * new modification to enhance optimisation in admin side
                 * fill in a collection of campaign with basic data
                 * */
                let o_newCamP_admin = {
                  s_comp_id: user?.id,
                  s_camp_id: response.id,
                  s_comp_nme: oCampDet.companyName,
                  s_camp_nme: oCampDet.campaignName,
                  s_date: oCampDet?.startingDate,
                  e_date: oCampDet?.endingDate,
                  b_all_inf_aprv: false,
                };
                // update all campaign collection
                await db
                  .collection('allcamps')
                  .doc(response.id)
                  .set({ ...o_newCamP_admin });

                if (docUploadFile !== null) {
                  var docRef = firebase.storage().ref(`campaign_document/${user?.email}/${response.id}/campaignDoc`);

                  // Store the document:
                  docRef.put(docUploadFile).then(() => {
                    console.log('Document uploaded!');
                    dispatch(setMsg('Document saved.'));

                    // Now get image from storage and display in div...
                    docRef
                      .getDownloadURL()
                      .then(async (doc_url) => {
                        let newOCampDet = { ...oCampDet, camp_doc_url: doc_url };
                        db.collection('client')
                          .doc(user?.id)
                          .collection('campaign')
                          .doc(response.id)
                          .set({ oCampDet: newOCampDet }, { merge: true });

                        // 4- retrieves the newly created campaign id - response.id
                        // 5- assign new campaign to chosen influencers

                        dispatch(setMsg('Assign campaign to chosen influencers')); // loading message
                        let b_chk_valid = await _update_inf_doc(
                          /* newOCampDet, */ [],
                          response.id,
                          /* oCampDet, */ newOCampDet,
                          inFluencerChosenArr
                        );

                        dispatch(setMsg('Process emails')); // loading message
                        // 6- send emails to client, admins and influencers
                        if (b_chk_valid) {
                          _sendRecruitEmails(
                            newOCampDet,
                            inFluencerChosenArr,
                            o_dates?.startDateFormat,
                            o_dates?.endDateFormat
                          );
                        }
                      })
                      .catch((error) => {
                        console.log('There was an error: ' + error);
                        dispatch(setMsg(''));
                        dispatch(setLoading(false));
                      });
                  });
                } else {
                  // 4- retrieves the newly created campaign id - response.id
                  // 5- assign new campaign to chosen influencers

                  dispatch(setMsg('Assign campaign to chosen influencers')); // loading message
                  let b_chk_valid = await _update_inf_doc(aCampOpt, response.id, oCampDet, inFluencerChosenArr);

                  dispatch(setMsg('Process emails')); // loading message
                  // 6- send emails to client, admins and influencers
                  if (b_chk_valid) {
                    _sendRecruitEmails(oCampDet, inFluencerChosenArr, o_dates?.startDateFormat, o_dates?.endDateFormat);
                  }
                }
              });
          }
        }
      }
    }
  };

  /**
   * return array of object containing influencer chosen details to be saved in campaign
   */
  async function _prep_aInflu_obj(s_type, a_influ_recruit, a_influ_add_new) {
    return await new Promise((resolve) => {
      let inFluencerChosenArr = [];
      let quotePriceListArr = [];
      let a_influ = [];

      if (s_type === 'add_new') {
        a_influ = [...a_influ_add_new];
      } else if (s_type === 'recruit') {
        a_influ = [...a_influ_recruit];
      }

      a_influ?.forEach((inf) => {
        if (inf?.data?.selected) {
          let totalPrice = 0;
          let remunerationAmt = 0;
          let storiesPrice = 0;
          let gridsPrice = 0;
          let igtvsPrice = 0;

          //get influencer Chosen price amount for the quotation
          let priceCal = Number(inf.data.postRate);
          if (inf?.storyCount > 0) {
            storiesPrice = Number(inf.storyCount) * 1150; // 999;
            totalPrice = Number(totalPrice) + Number(storiesPrice);
            quotePriceListArr.push({
              id: inf.id,
              contentType: 'story',
              qty: inf.storyCount,
              unitPrice: 1150, // 999,
              amt: storiesPrice,
            });
          }
          if (inf?.gridCount > 0) {
            let gridObj = {
              id: inf.id,
              contentType: 'grid',
              qty: inf.gridCount,
              unitPrice: priceCal,
            };

            if (inf.gridCount === 1) {
              gridsPrice = priceCal;
            } else if (inf.gridCount === 2) {
              let i_second_grid_price = priceCal - 500;
              gridsPrice = Number(priceCal) + Number(i_second_grid_price);
              gridObj = { ...gridObj, i_second_grid_price: i_second_grid_price };
            } else if (inf.gridCount > 2) {
              let i_other_count = 0;
              let i_second_grid_price = Number(priceCal) - 500;
              let i_other_grid_price = i_second_grid_price - 500;
              i_other_count = inf.gridCount - 2;

              let otherPrice = i_other_count * i_other_grid_price;
              gridsPrice = Number(gridsPrice) + Number(priceCal) + i_second_grid_price; // price for first and second grids
              gridsPrice = Number(gridsPrice) + otherPrice; // price for the remaining grids
              gridObj = {
                ...gridObj,
                i_second_grid_price: i_second_grid_price,
                i_other_count: i_other_count,
                i_other_grid_price: i_other_grid_price,
                otherPrice: otherPrice,
              };
            }

            /* gridsPrice = Number(inf.gridCount) * priceCal; */
            totalPrice = Number(totalPrice) + Number(gridsPrice);

            gridObj = { ...gridObj, amt: gridsPrice };
            quotePriceListArr.push({ ...gridObj });
          }
          /* if (inf?.igtvCount > 0) {
            igtvsPrice = Number(inf.igtvCount) * priceCal;
            totalPrice = Number(totalPrice) + Number(igtvsPrice);
            quotePriceListArr.push({
              id: inf.id,
              contentType: 'igtv',
              qty: inf.igtvCount,
              unitPrice: priceCal,
              amt: igtvsPrice,
            });
          } */

          remunerationAmt = 0.55 * Number(totalPrice);
          //get influencer Chosen details
          inFluencerChosenArr.push({
            id: inf.id,
            name: inf?.data?.info?.name || '',
            email: inf?.data?.email || '',
            storyCount: inf?.storyCount || 0,
            gridCount: inf?.gridCount || 0,
            igtvCount: inf?.igtvCount || 0,
            storyPostRate: 1150, // 999,
            gridPostRate: Math.round(inf.data.postRate),
            igtvPostRate: Math.round(inf.data.postRate),
            totalStoriesPrice: Math.round(storiesPrice),
            totalGridsPrice: Math.round(gridsPrice),
            totalIgtvsPrice: Math.round(igtvsPrice),
            approvedToWork: 'not_yet_approved',
            totalPrice: Math.round(totalPrice),
            remunerationAmt: Math.round(remunerationAmt),
          });
        }
      });

      if (inFluencerChosenArr?.length > 0) {
        resolve({
          quotePriceListArr: quotePriceListArr,
          inFluencerChosenArr: inFluencerChosenArr,
        });
      }
    });
  }

  /**
   * use when type is add_new
   * calculate new total price object
   * return object
   */
  async function _calculateTotals(o_total_new, o_total_prev) {
    return await new Promise((resolve) => {
      let finalTotalGridAmt = Number(o_total_new?.gridAmt || 0) + Number(o_total_prev?.gridAmt || 0);
      let finalTotalIgtvAmt = Number(o_total_new?.igtvAmt || 0) + Number(o_total_prev?.igtvAmt || 0);
      let finalTotalStoryAmt = Number(o_total_new?.storyAmt || 0) + Number(o_total_prev?.storyAmt || 0);
      let finalTotalAmt = finalTotalGridAmt + finalTotalIgtvAmt + finalTotalStoryAmt;

      let oNewTotalAmt = {
        gridAmt: finalTotalGridAmt || 0,
        igtvAmt: finalTotalIgtvAmt || 0,
        storyAmt: finalTotalStoryAmt || 0,
        total: finalTotalAmt || 0,
      };

      let newVat = 0.15 * Number(finalTotalAmt || 0);
      let newGrandTotal = Number(finalTotalAmt || 0) + Number(newVat || 0);

      resolve({
        oNewTotalAmt: oNewTotalAmt || 0,
        newVat: newVat || 0,
        finalTotalAmt: finalTotalAmt || 0,
        newGrandTotal: newGrandTotal || 0,
      });
    });
  }

  const _sendEmailsAddNew = (a_influ, o_camp_dates) => {
    // formating the unix timestamp to DD-MM-YYYY
    let startDate = '';
    let endDate = '';
    if (o_camp_dates?.startingDate.seconds) {
      startDate = moment(new Date(o_camp_dates?.startingDate?.seconds * 1000)).format('DD-MM-YYYY');
    } else {
      startDate = moment(o_camp_dates?.startingDate?.toDate()).format('DD-MM-YYYY');
    }

    if (o_camp_dates?.endingDate.seconds) {
      endDate = moment(new Date(o_camp_dates?.endingDate?.seconds * 1000)).format('DD-MM-YYYY');
    } else {
      endDate = moment(o_camp_dates?.endingDate?.toDate()).format('DD-MM-YYYY');
    }

    let adminEmailsArr = process.env.REACT_APP_ADMIN_EMAILS.split(',');

    a_influ.forEach(async (inf) => {
      //prepare email parameters
      let emailArr = [
        {
          //email to influencer
          to: inf.email,
          adminEmail: process.env.REACT_APP_SENDGRID_ADMIN_EMAIL,
          emailId: process.env.REACT_APP_CLIENT_ADD_INFLUENCER_INFLUENCER_EMAIL,
          attachment: false,
          returnMsg: 'Email sent to influencer',
          emailParams: {
            subject: 'You have been chosen!',
            influencerName: inf.name,
            companyName: campDetObj.data.oCampDet.companyName,
            campaignName: campDetObj.data.oCampDet.campaignName,
            startingDate: startDate,
            endingDate: endDate,
            storyCount: inf.storyCount,
            gridCount: inf.gridCount,
            igtvCount: inf.igtvCount,
            remunerationAmt: Math.round(inf.remunerationAmt),
            website_url: process.env.REACT_APP_WEBSITE_URL,
          },
        },
        {
          //email to client
          to: user?.email,
          adminEmail: process.env.REACT_APP_SENDGRID_ADMIN_EMAIL,
          emailId: process.env.REACT_APP_CLIENT_REMOVE_INFLUENCER_CLIENT_EMAIL,
          attachment: false,
          returnMsg: 'Influencer added successfully',
          emailParams: {
            subject: 'Influencer ' + inf.name + ' added successfully',
            influencerName: inf.name,
            companyName: campDetObj.data.oCampDet.companyName,
            campaignName: campDetObj.data.oCampDet.campaignName,
          },
        },
        {
          //email to admin
          to: adminEmailsArr,
          adminEmail: process.env.REACT_APP_SENDGRID_ADMIN_EMAIL,
          emailId: process.env.REACT_APP_CLIENT_ADD_INFLUENCER_ADMIN_EMAIL,
          attachment: false,
          returnMsg: 'Email sent to administration successfully',
          emailParams: {
            subject:
              'Company ' +
              campDetObj.data.oCampDet.companyName +
              ' added new influencer ' +
              inf.name +
              ' to campaign ' +
              campDetObj.data.oCampDet.campaignName,
            influencerName: inf.name,
            companyName: campDetObj.data.oCampDet.companyName,
            campaignName: campDetObj.data.oCampDet.campaignName,
          },
        },
      ];

      const sendEmail = functions.httpsCallable('sendEmail');
      emailArr.forEach(async (email) => {
        await sendEmail({ email: email })
          .then(async (response) => {
            enqueueSnackbar(response?.data?.result?.msg);
            // navigate(PATH_DASHBOARD.client.campaignDetail); // go to campaign details page
            dispatch(setMsg(''));
            dispatch(setLoading(false));
          })
          .catch((err) => {
            enqueueSnackbar(`Error occured while sending emails: ${err?.message}`);
            dispatch(setLoading(false));
          });
      });
    });

    setOpenAddNew(false);
    dispatch(setMsg('')); // clear loading message
    dispatch(setLoading(false));

    // re-initialise step redux
    dispatch(setFormNewInfActiveStep(0));
  };

  async function _formatDate(_o_dates) {
    return await new Promise((resolve) => {
      let startDateFormat = '';
      let endDateFormat = '';
      let strInDate = '';
      let endInDate = '';

      if (_o_dates?.startingDate instanceof Date) {
        strInDate = _o_dates.startingDate;
        startDateFormat = formatDate(strInDate);
      } else {
        strInDate = new Date(_o_dates.startingDate);
        startDateFormat = formatDate(strInDate);
      }

      if (_o_dates?.endingDate instanceof Date) {
        endInDate = _o_dates.endingDate;
        endDateFormat = formatDate(endInDate);
      } else {
        endInDate = new Date(_o_dates.endingDate);
        endDateFormat = formatDate(endInDate);
      }

      resolve({
        strInDate: strInDate,
        startDateFormat: startDateFormat,
        endInDate: endInDate,
        endDateFormat: endDateFormat,
      });
    });
  }

  async function _update_inf_doc(a_camp_opt, s_new_camp_id, oCampDet, a_inf) {
    return await new Promise(async (resolve) => {
      // let campStatus = await getCampStatusMsg('pending', a_inf);

      // check if message is fetched
      // if (campStatus) {
      /**
       *  update array of campaigns - a_camp
       * return updated array of campaigns
       * aCampOpt is from redux slice
       * */
      // let a_new_camp = await _core_create_a_camp_msg(a_camp_opt, s_new_camp_id, campStatus, oCampDet?.campaignName);

      // if (a_new_camp.length > 0) {
      // Update campaign doc + influencer doc

      // update all influencers
      a_inf.forEach(async (inf) => {
        await db.collection('influencer').doc(inf.id).collection('campaign').doc(s_new_camp_id).set({
          oCampDet: oCampDet,
          aInfluChosen: a_inf,
          statusCode: 'pending',
          approvedToWork: 'not_yet_approved',
          rejected: false,
          clientId: user?.id,
          clientEmail: user?.email,
          campaignId: s_new_camp_id,
          storyCount: inf.storyCount,
          gridCount: inf.gridCount,
          igtvCount: inf.igtvCount,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
      });

      resolve(true);

      // update a_camp doc
      /* await db
            .collection('client')
            .doc(user?.id)
            .collection('campaign')
            .doc('a_camp')
            .set({ a_camp: a_new_camp }, { merge: true })
            .then(() => {
              resolve(true);
            });
        } */
      /*    } else {
        enqueueSnackbar('Error fetching campaign status', { variant: 'error' });
        dispatch(setMsg(''));
        dispatch(setLoading(''));
      } */
    });
  }

  const _sendRecruitEmails = (oCampDet, a_inf, startDateFormat, endDateFormat) => {
    const sendEmail = functions.httpsCallable('sendEmail');
    let adminEmailsArr = process.env.REACT_APP_ADMIN_EMAILS.split(',');

    //prepare email parameters for client and admin
    let emailArr = [
      {
        //email to client
        to: user?.email,
        adminEmail: process.env.REACT_APP_SENDGRID_ADMIN_EMAIL,
        emailId: process.env.REACT_APP_CLIENT_CREATE_CAMPAIGN_CLIENT_EMAIL,
        attachment: false,
        returnMsg: 'Campaign created successfully',
        emailParams: {
          subject: 'Campaign ' + oCampDet?.campaignName + ' has been created.',
          companyName: oCampDet?.companyName,
          campaignName: oCampDet?.campaignName,
        },
      },
      {
        //email to admin
        to: adminEmailsArr,
        adminEmail: process.env.REACT_APP_SENDGRID_ADMIN_EMAIL,
        emailId: process.env.REACT_APP_CLIENT_CREATE_CAMPAIGN_ADMIN_EMAIL,
        attachment: false,
        returnMsg: 'Email sent to administration successfully',
        emailParams: {
          subject: 'A new campaign ' + oCampDet?.campaignName + ' has been created',
          companyName: oCampDet?.companyName,
          campaignName: oCampDet?.campaignName,
        },
      },
    ];

    // send emails to client and admin
    emailArr.forEach(async (email) => {
      await sendEmail({ email: email })
        .then(async (response) => {
          enqueueSnackbar(response?.data?.result?.msg, { variant: 'success' });
          dispatch(setLoading(false));
        })
        .catch((err) => {
          enqueueSnackbar(`Error while sending email to client: ${err?.message}`, { variant: 'error' });
          dispatch(setLoading(false));
        });
    });

    // loop through the chosen influencer and prepare email objects for each of them
    a_inf.forEach(async (inf) => {
      let emailParams = {
        influencerName: inf.name,
        companyName: oCampDet?.companyName,
        campaignName: oCampDet?.campaignName,
        startingDate: startDateFormat,
        endingDate: endDateFormat,
        storyCount: inf.storyCount,
        gridCount: inf.gridCount,
        igtvCount: inf.igtvCount,
        remunerationAmt: Math.round(inf.remunerationAmt),
        website_url: process.env.REACT_APP_WEBSITE_URL,
      };

      //pass email parameters and call send recruit function
      const sendInfluencerEmail = functions.httpsCallable('sendInfluencerEmail');
      await sendInfluencerEmail({
        adminEmail: process.env.REACT_APP_SENDGRID_ADMIN_EMAIL,
        emailId: process.env.REACT_APP_CLIENT_CREATE_CAMPAIGN_INFLUENCER_EMAIL,
        emailParams: emailParams,
        email: inf?.email,
      })
        .then(async (response) => {
          enqueueSnackbar(response?.data?.result, { variant: 'success' });
          resetRecruitSlice();
          dispatch(setMsg('')); // clear loading message
          dispatch(setLoading(false)); // stop loading message
        })
        .catch((err) => {
          enqueueSnackbar(`Error while sending email to influencer: ${err?.message}`, { variant: 'error' });
          dispatch(setMsg('')); // clear loading message
          dispatch(setLoading(false)); // stop loading message
        });
    });

    // setOpenAddNew(false);
    navigate(PATH_DASHBOARD.client.campaign);
    dispatch(setMsg('')); // clear loading message
    dispatch(setLoading(false)); // stop loading message

    // re-initialise step redux
    dispatch(setFormActiveStep(0));
  };

  const formatDate = (date) => {
    let day = new Date(date).getDate();
    let month = new Date(date).getMonth() + 1;
    let year = new Date(date).getFullYear();
    if (day < 10) {
      day = '0' + day.toString();
    }
    if (month < 10) {
      month = '0' + month.toString();
    }
    return day.toString() + '-' + month.toString() + '-' + year.toString();
  };

  const resetRecruitSlice = () => {
    dispatch(setCompanyName(''));
    dispatch(setCampaignName(''));
    dispatch(setStartingDate(new Date()));
    dispatch(setEndingDate(new Date()));
    dispatch(setBrandPhilo(''));
    dispatch(setProductToPromo(''));
    dispatch(setUsp(''));
    dispatch(setAgeRange(''));
    dispatch(setMarkSitua(''));
    dispatch(setObjective(''));
    dispatch(setOriginalInfluArr([]));
    dispatch(setInfluArr([]));
    dispatch(
      setTotalAmtObj({
        storyAmt: 0,
        gridAmt: 0,
        igtvAmt: 0,
        total: 0,
      })
    );
  };

  return (
    <>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <LabelStyle>
          {type && type === 'add_new'
            ? `${formNewInfActiveStep + 1}) Pricing confirmation`
            : `${formActiveStep + 1}) Pricing confirmation`}
        </LabelStyle>

        <hr />
        <br />
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            if (type === 'add_new') {
              dispatch(setFormNewInfActiveStep(formNewInfActiveStep - 1));
            } else if (type === 'recruit') {
              dispatch(setFormActiveStep(formActiveStep - 1));
            }
          }}
        >
          Back
        </Button>
        <br />
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8.5}>
            <Card>
              <Stack sx={{ p: 1 }}>
                <Scrollbar>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">
                            <Typography>Influencer</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Typography>Pricing</Typography>
                              <div style={{ padding: '5px' }}></div>
                              <Tooltip
                                title={
                                  <Typography variant="body1">
                                    There is a fixed price for story post compared to grid post. Price or first grid is
                                    fixed, a discount of Rs 500 for the second grid and a discount of Rs 1,000 for the
                                    other grids chosen.{' '}
                                  </Typography>
                                }
                                placement="top"
                              >
                                <InfoIcon color="info" />
                              </Tooltip>
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>Story</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>Grid</Typography>
                          </TableCell>
                          {/*
                          <TableCell align="center">
                            <Typography>IGTV</Typography>
                          </TableCell>
                            */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {type &&
                          type === 'add_new' &&
                          influArrAddNewInflu?.map((inf, index) => {
                            if (inf?.data?.selected) {
                              return (
                                <TableRow key={index}>
                                  <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'left' }}>
                                      <Box sx={{ position: 'relative' }}>
                                        <ButtonBase
                                          onClick={() => {
                                            setOpenPhoto(true);
                                            setPicUrl(inf?.data?.info?.profilePicUrl);
                                          }}
                                        >
                                          {renderAvatar(inf?.data?.info?.name, inf?.data?.info?.profilePicUrl)}
                                        </ButtonBase>
                                      </Box>
                                      <Box sx={{ ml: 2 }}>
                                        <Typography variant="subtitle2"> {inf?.data?.info?.name}</Typography>
                                        <Link
                                          href={`https://www.instagram.com/${inf?.data?.instagramUsername}/`}
                                          variant="body2"
                                          sx={{ color: 'text.secondary' }}
                                          rel="noopener noreferrer"
                                          target="_blank"
                                        >
                                          {inf?.data?.instagramUsername}
                                        </Link>
                                      </Box>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        minWidth: '7em',
                                      }}
                                    >
                                      <div>
                                        <Typography>Story: Rs 1150 {/* 999 */}</Typography>
                                      </div>
                                      <div>
                                        {/* <Typography>Grid: Rs {inf.data.postRate}</Typography> */}
                                        <Stack direction="row" alignItems="left" spacing={1}>
                                          <Typography variant="body2">Grid: Variable</Typography>
                                          <NoMaxWidthTooltip
                                            title={
                                              <>
                                                <Typography variant="body1" style={{ whiteSpace: 'normal' }}>
                                                  * Price for the first grid: Rs {inf?.data?.postRate}
                                                </Typography>
                                                <Typography variant="body1" style={{ whiteSpace: 'normal' }}>
                                                  * Price for the second grid: ( Rs {inf?.data?.postRate} - Rs 500 )
                                                </Typography>
                                                <Typography variant="body1" style={{ whiteSpace: 'normal' }}>
                                                  * Price for the other grids: ( Rs {inf?.data?.postRate} - Rs 1000 )
                                                </Typography>
                                              </>
                                            }
                                            placement="top"
                                          >
                                            <InfoIcon color="info" fontSize="medium" />
                                          </NoMaxWidthTooltip>
                                        </Stack>
                                      </div>
                                      {/* <div>
                                        <Typography>IGTV: Rs {inf.data.postRate}</Typography>
                                    </div> */}
                                    </div>
                                  </TableCell>
                                  <CustomTableCellNumber {...{ inf, name: 'storyCount', onChange }} />
                                  <CustomTableCellNumber {...{ inf, name: 'gridCount', onChange }} />
                                  {/* <CustomTableCellNumber {...{ inf, name: 'igtvCount', onChange }} /> */}
                                </TableRow>
                              );
                            } else return <Fragment key={index}></Fragment>;
                          })}
                        {type &&
                          type === 'recruit' &&
                          influArr?.map((inf, index) => {
                            if (inf?.data?.selected) {
                              return (
                                <TableRow key={index}>
                                  <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'left' }}>
                                      <Box sx={{ position: 'relative' }}>
                                        <ButtonBase
                                          onClick={() => {
                                            setOpenPhoto(true);
                                            setPicUrl(inf?.data?.info?.profilePicUrl);
                                          }}
                                        >
                                          {renderAvatar(inf?.data?.info?.name, inf?.data?.info?.profilePicUrl)}
                                        </ButtonBase>
                                      </Box>
                                      <Box sx={{ ml: 2 }}>
                                        <Typography variant="subtitle2"> {inf?.data?.info?.name}</Typography>
                                        <Link
                                          href={`https://www.instagram.com/${inf?.data?.instagramUsername}/`}
                                          variant="body2"
                                          sx={{ color: 'text.secondary' }}
                                          rel="noopener noreferrer"
                                          target="_blank"
                                        >
                                          {inf?.data?.instagramUsername}
                                        </Link>
                                      </Box>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        minWidth: '7em',
                                      }}
                                    >
                                      <div>
                                        <Typography variant="body2">Story: Rs 1150 {/* 999 */}</Typography>
                                      </div>
                                      <div>
                                        <Stack direction="row" alignItems="left" spacing={1}>
                                          <Typography variant="body2">Grid: Variable</Typography>
                                          <NoMaxWidthTooltip
                                            title={
                                              <>
                                                <Typography variant="body1" style={{ whiteSpace: 'normal' }}>
                                                  * Price for the first grid: Rs {inf?.data?.postRate}
                                                </Typography>
                                                <Typography variant="body1" style={{ whiteSpace: 'normal' }}>
                                                  * Price for the second grid: ( Rs {inf?.data?.postRate} - Rs 500 )
                                                </Typography>
                                                <Typography variant="body1" style={{ whiteSpace: 'normal' }}>
                                                  * Price for the other grids: ( Rs {inf?.data?.postRate} - Rs 1000 )
                                                </Typography>
                                              </>
                                            }
                                            placement="top"
                                          >
                                            <InfoIcon color="info" fontSize="medium" />
                                          </NoMaxWidthTooltip>
                                        </Stack>
                                      </div>
                                      {/* <div>
                                        <Typography variant="body2">IGTV: Rs {inf?.data?.postRate}</Typography>
                                    </div> */}
                                    </div>
                                  </TableCell>
                                  <CustomTableCellNumber {...{ inf, name: 'storyCount', onChange }} />
                                  <CustomTableCellNumber {...{ inf, name: 'gridCount', onChange }} />
                                  {/* <CustomTableCellNumber {...{ inf, name: 'igtvCount', onChange }} /> */}
                                </TableRow>
                              );
                            } else return <Fragment key={index}></Fragment>;
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <Card>
              <CardHeader title="Price Summary" />
              <CardContent>
                <Stack spacing={2}>
                  <Stack direction="row" justifyContent="space-between" alignItems={'center'}>
                    <Typography variant="body2">Total Amt for Stories (Rs)</Typography>
                    <Typography variant="body2">
                      {type && type === 'add_new' && totalAmtObjAddNewInflu
                        ? totalAmtObjAddNewInflu.storyAmt
                        : type && type === 'recruit' && totalAmtObj?.storyAmt
                        ? totalAmtObj.storyAmt
                        : 0}
                    </Typography>
                  </Stack>
                  <Divider />
                  <Stack direction="row" justifyContent="space-between" alignItems={'center'}>
                    <Typography variant="body2">Total Amt for Grids (Rs)</Typography>
                    <Typography variant="body2">
                      {type && type === 'add_new' && totalAmtObjAddNewInflu
                        ? totalAmtObjAddNewInflu.gridAmt
                        : type && type === 'recruit' && totalAmtObj?.gridAmt
                        ? totalAmtObj.gridAmt
                        : 0}
                    </Typography>
                  </Stack>
                  {/* <Divider />
                  <Stack direction="row" justifyContent="space-between" alignItems={'center'}>
                    <Typography variant="body2">Total Amt for IGTVs (Rs)</Typography>
                    <Typography variant="body2">
                      {type && type === 'add_new' && totalAmtObjAddNewInflu
                        ? totalAmtObjAddNewInflu.igtvAmt
                        : type && type === 'recruit' && totalAmtObj?.igtvAmt
                        ? totalAmtObj.igtvAmt
                        : 0}
                    </Typography>
                        </Stack> */}
                  <Divider />

                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle1">Total</Typography>
                    <Box sx={{ textAlign: 'right' }}>
                      <Typography variant="subtitle1" color="primary" /* sx={{ color: 'error.main' }} */>
                        <CurrencyFormat
                          value={
                            type && type === 'add_new' && totalAmtObjAddNewInflu?.total
                              ? Number(totalAmtObjAddNewInflu.total)
                              : type && type === 'recruit' && totalAmtObj?.total
                              ? Number(totalAmtObj.total)
                              : 0
                          }
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Rs '}
                        />
                      </Typography>
                      <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                        (VAT excluded)
                      </Typography>
                    </Box>
                  </Stack>

                  <Divider />
                  <Stack direction="row" alignItems={'center'} justifyContent="space-between" spacing={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={
                        type && type === 'add_new' && totalAmtObjAddNewInflu?.total === 0
                          ? true
                          : type && type === 'recruit' && totalAmtObj?.total === 0
                          ? true
                          : false
                      }
                      onClick={() => confirm()}
                    >
                      Confirm
                    </Button>
                    <Tooltip
                      title={
                        <Typography variant="body1">
                          After confirmation, we will notify the selected influencers about the campaign and ask them to
                          approve or decline the offer, then you will get notified after they respond. You will find all
                          the details in the `All campaigns` tab.
                        </Typography>
                      }
                      placement="top"
                    >
                      <InfoIcon color="info" fontSize="large" />
                    </Tooltip>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Dialog open={openPhoto} onClose={handleClose}>
        <ViewProfilePic picUrl={picUrl} />
      </Dialog>
    </>
  );
}

function renderAvatar(name, avatar) {
  return avatar ? (
    <Avatar alt={name} src={avatar} sx={{ width: 48, height: 48, boxShadow: (theme) => theme.customShadows.z8 }} />
  ) : null;
}

export default PricingDetails;
