import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated && user?.role === 'admin') {
    // return <Navigate to={PATH_DASHBOARD.root} />;
    return <Navigate to={PATH_DASHBOARD.admin.dashboard} />;
  } else if (isAuthenticated && user?.role === 'influencer') {
    return <Navigate to={PATH_DASHBOARD.influencer.dashboard} />;
  } else if (isAuthenticated && user?.role === 'client') {
    return <Navigate to={PATH_DASHBOARD.client.campaign} />;
  } else if (isAuthenticated && user?.role === 'pending-influencer') {
    return <Navigate to={PATH_DASHBOARD.influencer.proceedToInfRegister} />;
  }

  return <>{children}</>;
}
