import { Suspense, lazy } from 'react';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
// import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

const Settings = lazy(() => import('./components/settings'));
// const ScrollToTop = lazy(() => import('./components/ScrollToTop'));
const LoadingScreenCustom = lazy(() => import('./components/LoadingScreenCustom'));
// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          {/* <RtlLayout> */}
          <NotistackProvider>
            <MotionLazyContainer>
              <ProgressBarStyle />
              <ChartStyle />
              <Suspense fallback={<></>}>
                <Settings />
              </Suspense>
              {/*
              <Suspense fallback={<></>}>
                <Messaging />
              </Suspense>
              */}
              {/*<Suspense fallback={<></>}>
                <ScrollToTop />
              </Suspense>*/}
              <Suspense fallback={<></>}>
                <LoadingScreenCustom />
              </Suspense>
              <Router />
            </MotionLazyContainer>
          </NotistackProvider>
          {/* </RtlLayout> */}
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
