import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';
import Login from '../pages/auth/Login';

// ----------------------------------------------------------------------

PendingInfGuard.propTypes = {
  children: PropTypes.node,
};

export default function PendingInfGuard({ children }) {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Login />;
  }

  if (isAuthenticated && user?.role === 'pending-influencer') {
    return <>{children}</>;
  }

  /*
  else if (isAuthenticated && user?.role === 'admin') {
    return <Navigate to={PATH_DASHBOARD.admin.influencer} />;
  } else if (isAuthenticated && user?.role === 'influencer') {
    return <Navigate to={PATH_DASHBOARD.influencer.dashboard} />;
  } else if (isAuthenticated && user?.role === 'client') {
    return <Navigate to={PATH_DASHBOARD.client.campaign} />;
  }
  */
}
