import PropTypes from 'prop-types';
// @mui
import {
  Button,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  TextField,
  DialogActions,
  Card,
  CardHeader,
  Grid,
} from '@mui/material';
import { styled } from '@mui/styles';

// redux
import { useDispatch, useSelector } from '../../../../../redux/store';
import { selectRecruit, setDocUploadFile, setFormActiveStep } from '../../../../../redux/slices/recruitSlice';
import { useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import useResponsive from '../../../../../hooks/useResponsive';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

function Slide2({ current, index, handleNextClick, handlePreviousClick }) {
  const isDesktop = useResponsive('up', 'md');

  const slideRef = useRef(null);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const { docUploadFile, formActiveStep } = useSelector(selectRecruit);

  const [us_b_opn_upload, set_us_b_opn_upload] = useState(false);

  let classNames = 'slide';

  if (current === index) classNames += ' slide--current';
  else if (current - 1 === index) classNames += ' slide--previous';
  else if (current + 1 === index) classNames += ' slide--next';

  const onFileChange = async (e) => {
    if (e.target.files?.length > 0) {
      let o_file = e.target.files[0];
      dispatch(setDocUploadFile(o_file));
    }
  };

  const savePdf = () => {
    if (docUploadFile && docUploadFile?.name && docUploadFile?.name !== '') {
      dispatch(setFormActiveStep(formActiveStep + 1));
      set_us_b_opn_upload(false);
      /* var docRef = firebase.storage().ref(`campaign_document/${user?.email}/campaignDoc`);

      // Store the document:
      docRef.put(docUploadFile).then(function (snapshot) {
        console.log('Document uploaded!');
        dispatch(setMsg('Document saved.'));

        // Now get image from storage and display in div...
        docRef
          .getDownloadURL()
          .then(function (doc_url) {
            dispatch(setDocUploadUrl(doc_url));
            dispatch(setFormActiveStep(formActiveStep + 1));
            set_us_b_opn_upload(false);

            dispatch(setMsg(''));
            dispatch(setLoading(false));
          })
          .catch(function (error) {
            console.log('There was an error: ' + error);
            dispatch(setMsg(''));
            dispatch(setLoading(false));
          });
      }); */
    } else {
      enqueueSnackbar('No file uploaded', { variant: 'error' });
    }
  };

  return (
    <>
      <li
        ref={slideRef}
        className={classNames}
        style={{ width: isDesktop ? '50vmin' : '75vmin' }}
        // onClick={handleSlideOnClick}
        // onKeyDown={handleSlideOnClick}
        // onMouseMove={handleMouseMove}
        // onMouseLeave={handleMouseLeave}
      >
        <Card sx={{ p: 3, /* mb: 3, */ minHeight: '450px' }}>
          <CardHeader
            title={
              <LabelStyle>
                1) Upload campaign description or give your campaign details based on our questions?
              </LabelStyle>
            }
            sx={{ mb: 3 }}
          />

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  set_us_b_opn_upload(true);
                }}
                size="large"
                fullWidth
                style={{ whiteSpace: 'nowrap', textTransform: 'none' }}
                disabled={current !== index}
              >
                Upload description (pdf)
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  // setStep((step) => step + 1);
                  handleNextClick();
                  dispatch(setDocUploadFile(null));
                }}
                size="large"
                fullWidth
                disabled={current !== index}
                style={{ textTransform: 'none' }}
              >
                Answer question
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <br />
              <hr />
              <br />
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  // setStep((step) => step - 1);
                  handlePreviousClick();
                }}
                size="large"
                disabled={current !== index}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </Card>
      </li>

      <>
        <Dialog
          open={us_b_opn_upload}
          keepMounted
          onClose={() => {
            dispatch(setDocUploadFile({}));
            set_us_b_opn_upload(false);
          }}
          aria-labelledby="uploadDescription"
          aria-describedby="uploadDescription"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="uploadDescriptionTitle" align="center">
            <Typography>
              <b>Upload description - pdf file</b>
            </Typography>
          </DialogTitle>
          <DialogContent align="center" style={{ maxHeight: '75vh', width: 'auto', marginTop: '1em' }}>
            <TextField
              className="uploadInput"
              variant="outlined"
              size="small"
              //fullWidth
              name="uploadInput"
              type="file"
              id="uploadInput"
              //value={docUploadFile?.name || ''}
              accept="application/pdf"
              onChange={(event) => onFileChange(event)}
              disabled={current !== index}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => savePdf()} size="large">
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                dispatch(setDocUploadFile({}));
                set_us_b_opn_upload(false);
              }}
              size="large"
              disabled={current !== index}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
}

// ----------------------------------------------------------------------

Slide2.propTypes = {
  current: PropTypes.number,
  index: PropTypes.number,
  handleNextClick: PropTypes.func,
  handlePreviousClick: PropTypes.func,
};

export default Slide2;
