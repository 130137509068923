import { createSlice } from '@reduxjs/toolkit';
// ----------------------------------------------------------------------

export const influencerFiltersSlice = createSlice({
  name: 'influencerFilters',
  initialState: {
    isLoading: false,
    error: null,
    sortBy: null,
    filters: {
      search: '',
      followerBase: '',
      engRate: '',
    },
  },
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setFilterInfluencers(state, action) {
      // state.filters.gender = action.payload.gender;
      // state.filters.category = action.payload.category;
      // state.filters.colors = action.payload.colors;
      // state.filters.priceRange = action.payload.priceRange;
      state.filters.search = action.payload.search;
      state.filters.followerBase = action.payload.followerBase;
      state.filters.engRate = action.payload.engRate;
    },

    resetInfluencerFilter: (state) => {
      state.isLoading = false;
      state.error = null;
      state.sortBy = null;
      state.filters = {
        search: '',
        followerBase: '',
        engRate: '',
      };
    },
  },
});

export const { startLoading, hasError, setFilterInfluencers, resetInfluencerFilter } = influencerFiltersSlice.actions;

export const selectInfluencerFilters = (state) => state.influencerFilters;

export default influencerFiltersSlice.reducer;
