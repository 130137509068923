import PropTypes from 'prop-types';
// @mui
import { Box } from '@mui/material';
// components
import { SkeletonProductItem } from '../../../../../../components/skeleton';
//
import InfluencerCard from './InfluencerCard';

// ----------------------------------------------------------------------

InfluencerList.propTypes = {
  type: PropTypes.string,
  influencers: PropTypes.array.isRequired,
  loading: PropTypes.bool,
};

export default function InfluencerList({ type, influencers, loading }) {
  return (
    <Box
      sx={{
        display: 'grid',
        gap: 3,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(4, 1fr)',
        },
      }}
    >
      {(loading ? [...Array(12)] : influencers).map((influencer, index) =>
        influencer ? (
          <InfluencerCard type={type} key={index} influencer={influencer} />
        ) : (
          <SkeletonProductItem key={index} />
        )
      )}
    </Box>
  );
}
