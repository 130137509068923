import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/styles';
// @mui
import { Container, Typography, Stack, Button } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../../../../../redux/store';
// hooks
import useSettings from '../../../../../../hooks/useSettings';
// components
import Page from '../../../../../../components/Page';
// sections
import InfluencerSearch from './InfluencerSearch';
import InfluencerList from './InfluencerList';
import { setFilterInfluencers, selectInfluencerFilters } from '../../../../../../redux/slices/InfluencerFiltersSlice';
import InfluencerFilterSidebar from './InfluencerFilterSidebar';
import InfluencerTagFiltered from './InfluencerTagFiltered';
import { selectRecruit, setFormActiveStep, setInfluArr } from '../../../../../../redux/slices/recruitSlice';
import { selectInfluencer } from '../../../../../../redux/slices/influencerSlice';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

export default function InfluencerDetails() {
  const { themeStretch } = useSettings();

  const dispatch = useDispatch();

  const { influencerList } = useSelector(selectInfluencer);

  const { filters } = useSelector(selectInfluencerFilters);

  const { influArr, formActiveStep } = useSelector(selectRecruit);

  const [openFilter, setOpenFilter] = useState(false);

  const filteredProducts = applyFilter(influArr, filters);

  const [isProceedValid, setIsProceedValid] = useState(false);

  const [isDefault, setIsDefault] = useState(true);

  const temp_influencer = useRef();

  const temp_default = useRef();

  const temp_selected_influencer = useRef();

  // initialise influencer array - need to declare selected, storycount, gridcount and igtvcount variables in all elements of the array
  // the selected variable will be used in choosing influencers from list
  // the storycount, gridcount and igtvcount variables will be used in the pricing section
  useEffect(() => {
    temp_influencer.current();
  }, [influencerList]);

  // check if there are changes made to the filters - in order to show filters tags
  useEffect(() => {
    temp_default.current();
  }, [filters]);

  // check if there are selected influencers in the influArr redux slice
  // if there are some present -> activate proceed button
  useEffect(() => {
    temp_selected_influencer.current();
  }, [influArr]);

  const _ue_init_influ = () => {
    let a_new_influ = []; // to contain new data

    // check if influencer list contains data
    if (influencerList && influencerList?.length > 0) {
      influencerList.forEach((influ) => {
        a_new_influ.push({
          id: influ.id,
          selected: false,
          storyCount: 0,
          gridCount: 0,
          igtvCount: 0,
          data: influ.data,
        });
      });
      dispatch(setInfluArr(a_new_influ));
    }
  };

  temp_influencer.current = _ue_init_influ;

  const _ue_chk_default = () => {
    if (filters) {
      if (filters?.followerBase === '' && filters?.engRate === '') {
        setIsDefault(true);
      } else {
        setIsDefault(false);
      }
    } else {
      setIsDefault(true);
    }
  };

  temp_default.current = _ue_chk_default;

  const _ue_chk_selected_influ = () => {
    // check if influencer list contains data
    if (influArr && influArr?.length > 0) {
      let validBool = false;
      influArr.forEach((inf) => {
        if (inf?.data?.selected) {
          validBool = true;
        }
      });
      setIsProceedValid(validBool);
    }
  };

  temp_selected_influencer.current = _ue_chk_selected_influ;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    dispatch(setFilterInfluencers({ ...filters, followerBase: '', engRate: '' }));
    handleCloseFilter();
  };

  const handleRemoveFollowerBase = () => {
    dispatch(setFilterInfluencers({ ...filters, followerBase: '' }));
  };

  const handleRemoveEngRate = () => {
    dispatch(setFilterInfluencers({ ...filters, engRate: '' }));
  };

  return (
    <Page title="Step 2: Start a campaign">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <LabelStyle>2) Please choose the influencers for the campaign</LabelStyle>
        <hr />
        <br />
        <br />
        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ sm: 'center' }}
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <InfluencerSearch influencers={filteredProducts} applyFilter={applyFilter} filters={filters} />
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                dispatch(setFormActiveStep(formActiveStep - 1));
              }}
            >
              Back
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                dispatch(setFormActiveStep(formActiveStep + 1));
              }}
              disabled={!isProceedValid}
            >
              Proceed
            </Button>
          </Stack>

          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <InfluencerFilterSidebar
              filters={filters}
              onResetAll={handleResetFilter}
              isOpen={openFilter}
              onOpen={handleOpenFilter}
              onClose={handleCloseFilter}
            />
          </Stack>
        </Stack>

        <Stack sx={{ mb: 3 }}>
          {!isDefault && (
            <>
              <Typography variant="body2" gutterBottom>
                <strong>{filteredProducts.length}</strong>
                &nbsp;Products found
              </Typography>

              <InfluencerTagFiltered
                filters={filters}
                isShowReset={!isDefault && !openFilter}
                onRemoveFollowerBase={handleRemoveFollowerBase}
                onRemoveEngRate={handleRemoveEngRate}
                onResetAll={handleResetFilter}
              />
            </>
          )}
        </Stack>

        <InfluencerList type={'default'} influencers={filteredProducts} loading={!influArr?.length && isDefault} />
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applyFilter(influencers, filters) {
  // SORT BY
  /*
  if (sortBy === 'featured') {
    products = orderBy(products, ['sold'], ['desc']);
  }
  if (sortBy === 'newest') {
    products = orderBy(products, ['createdAt'], ['desc']);
  }
  if (sortBy === 'priceDesc') {
    products = orderBy(products, ['price'], ['desc']);
  }
  if (sortBy === 'priceAsc') {
    products = orderBy(products, ['price'], ['asc']);
  }
  */
  // FILTER PRODUCTS
  /*
  if (filters.gender.length > 0) {
    products = products.filter((product) => filters.gender.includes(product.gender));
  }
  if (filters.category !== 'All') {
    products = products.filter((product) => product.category === filters.category);
  }
  if (filters.colors.length > 0) {
    products = products.filter((product) => product.colors.some((color) => filters.colors.includes(color)));
  }
  if (filters.priceRange) {
    products = products.filter((product) => {
      if (filters.priceRange === 'below') {
        return product.price < 25;
      }
      if (filters.priceRange === 'between') {
        return product.price >= 25 && product.price <= 75;
      }
      return product.price > 75;
    });
  }
  if (filters.rating) {
    products = products.filter((product) => {
      const convertRating = (value) => {
        if (value === 'up4Star') return 4;
        if (value === 'up3Star') return 3;
        if (value === 'up2Star') return 2;
        return 1;
      };
      return product.totalRating > convertRating(filters.rating);
    });
  }
  */
  if (filters?.search) {
    let s_srch = filters?.search?.toLowerCase();
    influencers = influencers.filter((inf) => {
      let s_ig_nme = inf?.data?.info?.name?.toLowerCase();
      if (s_ig_nme) {
        return s_ig_nme.includes(s_srch);
      } else return {};
    });
  }
  if (filters?.followerBase) {
    influencers = influencers.filter((inf) => {
      if (filters.followerBase === 'below') {
        return inf.data.realFolCou < 15000;
      }
      if (filters.followerBase === 'between') {
        return inf.data.realFolCou >= 15000 && inf.data.realFolCou <= 30000;
      }
      return inf.data.realFolCou > 30000;
    });
  }
  if (filters?.engRate) {
    influencers = influencers.filter((inf) => {
      if (filters.engRate === 'below') {
        return inf.data.engRate < 25;
      }
      if (filters.engRate === 'between') {
        return inf.data.engRate >= 25 && inf.data.engRate <= 75;
      }
      return inf.data.engRate > 75;
    });
  }
  return influencers;
}
