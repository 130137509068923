import axios from 'axios';
import { useSnackbar } from 'notistack';
// @mui
import { Stack, Button } from '@mui/material';
import db, { auth, provider } from '../../../firebase';
import useAuth from '../../../hooks/useAuth';
import PropTypes from 'prop-types';
// ----------------------------------------------------------------------

CheckInfluencer.propTypes = {
  type: PropTypes.string,
};

export default function CheckInfluencer({ type }) {
  const { enqueueSnackbar } = useSnackbar();

  const { login_inf } = useAuth();

  const signInWithFB = async () => {
    // await auth.setPersistence('session').then(async () => {
    auth
      .signInWithPopup(provider)
      .then(async (result) => {
        if (result.credential) {
          console.log('In method sign in with popup');
          console.log(result);
          var { credential } = result;
          var user_fb = result.additionalUserInfo.profile;
          var userId = result.user.multiFactor.user.uid;

          var token = credential.accessToken;

          let newToken = await getLongToken(token);
          if (newToken !== null && newToken !== '') {
            await processInfReg(userId, user_fb?.email, newToken, user_fb);
          }
        }
      })
      .catch((error) => {
        // Handle Errors here.
        var errorMessage = error.message;
        enqueueSnackbar(`Error occured: ${errorMessage}`, { variant: 'error' });
        // The email of the user's account used.
        var { email } = error;
        enqueueSnackbar(`Error occured with your email: ${email}`, { variant: 'error' });
        // The firebase.auth.AuthCredential type that was used.
        var { credential } = error;
        enqueueSnackbar(`Error occured with your credentials: ${credential}`, { variant: 'error' });
      });
    // });
  };

  const getLongToken = async (token) =>
    await new Promise((resolve) => {
      if (token !== '') {
        const url = `${process.env.REACT_APP_URL_PREFIX}/oauth/access_token?grant_type=fb_exchange_token&client_id=${process.env.REACT_APP_CLIENT_ID}&client_secret=${process.env.REACT_APP_CLIENT_SECRET}&fb_exchange_token=${token}`;
        axios
          .get(url)
          .then(async (res) => {
            resolve(res?.data?.access_token);
          })
          .catch((err) => {
            enqueueSnackbar(`Error occcured : ${err?.message}`, { variant: 'error' });
            resolve(null);
          });
      } else {
        enqueueSnackbar('We could not find your access token', { variant: 'error' });
        resolve(null);
      }
    });

  const processInfReg = async (id, email, token, user_fb) => {
    // verify email address is present in influencer table
    console.log('In method processInfReg');
    let isPrsntObj = await chkIfEmailPrsnt(email);
    console.log('verify email: ', isPrsntObj);
    if (isPrsntObj !== null && isPrsntObj?.id !== '' && isPrsntObj?.id !== undefined) {
      console.log('isPrsntObj', isPrsntObj);
      console.log('email present');
      // present
      if (id !== isPrsntObj?.id) {
        // email same but id not same => change data uid + delete previous uid
        await rebuildData(id, isPrsntObj?.id, isPrsntObj?.data, token, user_fb?.id);
      } else if (isPrsntObj?.data?.access === true) {
        // email + id same => update token
        updateToken(id, token, 'not_new', email, user_fb?.id, isPrsntObj?.data?.pendingReg);
        login_inf(id, isPrsntObj?.data, token, 'influencer', isPrsntObj?.data?.access);
      } else if (isPrsntObj?.data?.role === 'pending-influencer') {
        let profile = {
          email: email,
          name: user_fb?.name,
        };
        login_inf(id, profile, token, 'pending-influencer', false);
      } else {
        auth.signOut();
      }
    } else {
      console.log('email absent');
      // email not present
      updateToken(id, token, 'new', email, user_fb?.id, false);
      let profile = {
        email: email,
        name: user_fb?.name,
      };
      login_inf(id, profile, token, 'pending-influencer', false);
    }
  };

  const chkIfEmailPrsnt = async (email) =>
    await new Promise(async (resolve) => {
      await db
        .collection('influencer')
        .where('email', '==', email)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot?.docs?.length > 0) {
            let arr = [];
            querySnapshot?.docs?.forEach((doc) => {
              arr.push({
                id: doc.id,
                data: doc.data(),
              });
            });
            resolve({
              id: arr[0]?.id,
              data: arr[0].data,
            });
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log('Error getting documents: ', error);
        });
    });

  const rebuildData = async (newId, prevId, prevData, token, fbId) => {
    var batch = db.batch();
    var newDocRef = db.collection('influencer').doc(newId);
    var delDocRef = db.collection('influencer').doc(prevId);
    batch.set(newDocRef, { ...prevData, token: token, fbId: fbId }, { merge: true });
    batch.delete(delDocRef);
    await batch.commit().then(() => {
      login_inf(newId, prevData, token, 'influencer', prevData?.access);
    });
  };

  const updateToken = async (id, token, type, email, fbId, pendingReg) => {
    let updateObj = { token: token, fbId: fbId, pendingReg: pendingReg };
    if (type === 'new') {
      updateObj = {
        ...updateObj,
        access: false,
        role: 'pending-influencer',
        course_status: 'none',
        email: email,
        type: 'influencer',
      };
    }
    await db
      .collection('influencer')
      .doc(id)
      .set({ ...updateObj }, { merge: true });
  };

  return (
    <Stack spacing={3} sx={{ mt: 5 }}>
      <Button variant="contained" color="primary" size="large" fullWidth onClick={signInWithFB}>
        {type} WITH FACEBOOK
      </Button>
    </Stack>
  );
}
