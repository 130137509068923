import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Chip, Typography, Stack, Button } from '@mui/material';
// components
import Iconify from '../../../../../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  flexGrow: 1,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
});

const WrapperStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  alignItems: 'stretch',
  margin: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.divider}`,
}));

const LabelStyle = styled((props) => <Typography component="span" variant="subtitle2" {...props} />)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  borderRight: `solid 1px ${theme.palette.divider}`,
}));

// ----------------------------------------------------------------------

function labelFollowerBase(range) {
  if (range === 'below') {
    return 'Below 15k';
  }
  if (range === 'between') {
    return 'Between 15k - 30k';
  }
  return 'Above 30k';
}

function labelEngRate(range) {
  if (range === 'below') {
    return 'Below 25%';
  }
  if (range === 'between') {
    return 'Between 25% - 75%';
  }
  return 'Above 75%';
}

InfluencerTagFiltered.propTypes = {
  filters: PropTypes.object,
  isShowReset: PropTypes.bool,
  onRemoveFollowerBase: PropTypes.func,
  onRemoveEngRate: PropTypes.func,
  onResetAll: PropTypes.func,
};

export default function InfluencerTagFiltered({
  filters,
  isShowReset,
  onRemoveFollowerBase,
  onRemoveEngRate,
  onResetAll,
}) {
  const { followerBase, engRate } = filters;

  return (
    <RootStyle>
      {followerBase && (
        <WrapperStyle>
          <LabelStyle>Followers:</LabelStyle>
          <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
            <Chip
              size="small"
              label={labelFollowerBase(followerBase)}
              onDelete={onRemoveFollowerBase}
              sx={{ m: 0.5 }}
            />
          </Stack>
        </WrapperStyle>
      )}

      {engRate && (
        <WrapperStyle>
          <LabelStyle>Engagement rate:</LabelStyle>
          <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
            <Chip size="small" label={labelEngRate(engRate)} onDelete={onRemoveEngRate} sx={{ m: 0.5 }} />
          </Stack>
        </WrapperStyle>
      )}

      {isShowReset && (
        <Button color="error" size="small" onClick={onResetAll} startIcon={<Iconify icon={'ic:round-clear-all'} />}>
          Clear All
        </Button>
      )}
    </RootStyle>
  );
}
