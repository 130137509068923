import { createSlice } from '@reduxjs/toolkit';

export const influencerSlice = createSlice({
  name: 'influencer',
  initialState: {
    infCamps: [],
    influencerList: [],
    campIdChosen: '',
    campDetObj: {},
    displayOpt: {
      campaignsProject: true,
      campaignDetail: false,
    },
    a_camp: [],
    refreshAnalyticDone: false,
  },
  reducers: {
    setInfCamps: (state, action) => {
      state.infCamps = action.payload;
    },
    setInfluencerList: (state, action) => {
      state.influencerList = action.payload;
    },
    setCampIdChosenInf: (state, action) => {
      state.campIdChosen = action.payload;
    },
    setCampDetObjInf: (state, action) => {
      state.campDetObj = action.payload;
    },
    setDisplayOptInf: (state, action) => {
      state.displayOpt = action.payload;
    },
    setACampInf: (state, action) => {
      state.a_camp = action.payload;
    },
    setRefreshAnalyticDone: (state, action) => {
      state.refreshAnalyticDone = action.payload;
    },
    resetInfluencer: (state) => {
      state.infCamps = [];
      state.influencerList = [];
      state.campIdChosen = '';
      state.campDetObj = {};
      state.displayOpt = {
        campaignsProject: true,
        campaignDetail: false,
      };
      state.a_camp = [];
      state.refreshAnalyticDone = false;
    },
  },
});

export const {
  setInfCamps,
  setInfluencerList,
  setCampIdChosenInf,
  setCampDetObjInf,
  setDisplayOptInf,
  setACampInf,
  setRefreshAnalyticDone,
  resetInfluencer,
} = influencerSlice.actions;

export const selectInfluencer = (state) => state.influencer;

export default influencerSlice.reducer;
