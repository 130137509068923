import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
// @mui
import { Box, Button, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function PageNoAccess() {
  return (
    <Page title="No Access" sx={{ height: 1 }}>
      <RootStyle>
        <Container>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <Typography variant="h3" paragraph>
              No access just for now
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Don't worry, its only to inform you that you have been registered successfully but we only need to verify
              your details before giving you the access to the website. You will receive an email to inform you if the
              access has been granted. Thank you.
            </Typography>

            <br />
            <br />
            {/* <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} /> */}

            <Button to="/" size="large" variant="contained" component={RouterLink}>
              Go to Home
            </Button>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
