export function calculateInfluencerData(info, media, audDemoSc, conQua, influencerName) {
  return new Promise((resolve) => {
    const userPostData = [];
    let userFollowersCount = 0;
    // let userInstaId = "";
    let counter = 0;
    const likeCountArr = [];
    const commentCountArr = [];
    let infoDetails = {};

    if (info.name) {
      infoDetails = { ...infoDetails, name: info.name };
    } else {
      infoDetails = { ...infoDetails, name: influencerName };
    }
    /* if (info.profile_picture_url) {
      infoDetails = { ...infoDetails, profilePicUrl: info.profile_picture_url };
    } */
    if (info.biography) {
      infoDetails = { ...infoDetails, bio: info.biography };
    }

    if (media.data[0]) {
      userPostData.push(media.data[0]);
      likeCountArr.push(media.data[0].like_count);
      commentCountArr.push(media.data[0].comments_count);
      counter += 1;
    }
    if (media.data[1]) {
      userPostData.push(media.data[1]);
      likeCountArr.push(media.data[1].like_count);
      commentCountArr.push(media.data[1].comments_count);
      counter += 1;
    }
    if (media.data[2]) {
      userPostData.push(media.data[2]);
      likeCountArr.push(media.data[2].like_count);
      commentCountArr.push(media.data[2].comments_count);
      counter += 1;
    }
    if (media.data[3]) {
      userPostData.push(media.data[3]);
      likeCountArr.push(media.data[3].like_count);
      commentCountArr.push(media.data[3].comments_count);
      counter += 1;
    }
    if (media.data[4]) {
      userPostData.push(media.data[4]);
      likeCountArr.push(media.data[4].like_count);
      commentCountArr.push(media.data[4].comments_count);
      counter += 1;
    }
    /*
    if (media.data[5]) {
              userPostData.push(media.data[5]);
              //likeCountArr.push(media.data[5].like_count);
              //commentCountArr.push(media.data[5].comments_count);
              //counter+= 1;
            }
            if (media.data[6]) {
              userPostData.push(media.data[6]);
              //likeCountArr.push(media.data[6].like_count);
              //commentCountArr.push(media.data[6].comments_count);
              //counter+= 1;
            }
            if (media.data[7]) {
              userPostData.push(media.data[7]);
              //likeCountArr.push(media.data[7].like_count);
              //commentCountArr.push(media.data[7].comments_count);
              //counter+= 1;
            }
            if (media.data[8]) {
              userPostData.push(media.data[8]);
              //likeCountArr.push(media.data[8].like_count);
              //commentCountArr.push(media.data[8].comments_count);
              //counter+= 1;
            }
            */

    userFollowersCount = info.followers_count;
    // userInstaId = info.id;

    const avgLike = calculateAvg(likeCountArr, counter);

    const avgComment = calculateAvg(commentCountArr, counter);

    const totalAvgEng = Number(avgLike) + Number(avgComment);

    let engRate = (Number(totalAvgEng) / Number(userFollowersCount)) * 100;
    engRate = engRate.toFixed(2);

    const realFollowers = 100;

    const realFollowersCount = Number(userFollowersCount);

    const audienceDemo = Number(audDemoSc);

    const contentQua = Number(conQua);

    const followersScore = calFollowersSc(realFollowersCount, 5000, 0);
    // console.log("followersScore : " + followersScore);

    const likeScore = Math.round(Number(avgLike) / 4);

    const commentsScore = Number(avgComment);

    const totalEngScore = Number(likeScore) + Number(commentsScore);

    const subTotalScore = Number(followersScore) + Number(totalEngScore);

    const nichCoe = 1 + (Number(audienceDemo) + Number(contentQua));

    let trialScore = Number(subTotalScore) * Number(nichCoe) * 5;

    let gridPostRate = 0;
    if (trialScore > 6000 /* 7500 */) {
      gridPostRate = 6000 /* 7500 */;
    } else if (trialScore < 5000 /* 3500 */) {
      gridPostRate = 5000 /* 3500 */;
    } else {
      gridPostRate = trialScore;
    }

    trialScore = trialScore.toFixed(2);

    let objData = {
      info: infoDetails,
      avgLike: avgLike,
      avgComment: avgComment,
      numOfFol: userFollowersCount,
      totAvgEng: totalAvgEng,
      engRate: engRate,
      realFol: realFollowers,
      realFolCou: realFollowersCount,
      audDemoSc: audienceDemo,
      conQua: contentQua,
      follSc: followersScore,
      likeSc: likeScore,
      comSc: commentsScore,
      totEngSc: totalEngScore,
      subTotSc: subTotalScore,
      nichCoef: nichCoe,
      triSc: trialScore,
      postRate: gridPostRate,
    };

    if (userPostData[0]) {
      objData = {
        ...objData,
        post1: userPostData[0],
        like1: userPostData[0] && userPostData[0].like_count ? userPostData[0].like_count : '',
        comment1: userPostData[0] && userPostData[0].comments_count ? userPostData[0].comments_count : '',
      };
    }
    if (userPostData[1]) {
      objData = {
        ...objData,
        post2: userPostData[1],
        like2: userPostData[1] && userPostData[1].like_count ? userPostData[1].like_count : '',
        comment2: userPostData[1] && userPostData[1].comments_count ? userPostData[1].comments_count : '',
      };
    }
    if (userPostData[2]) {
      objData = {
        ...objData,
        post3: userPostData[2],
        like3: userPostData[2] && userPostData[2].like_count ? userPostData[2].like_count : '',
        comment3: userPostData[2] && userPostData[2].comments_count ? userPostData[2].comments_count : '',
      };
    }
    if (userPostData[3]) {
      objData = {
        ...objData,
        post4: userPostData[3],
        like4: userPostData[3] && userPostData[3].like_count ? userPostData[3].like_count : '',
        comment4: userPostData[3] && userPostData[3].comments_count ? userPostData[3].comments_count : '',
      };
    }
    if (userPostData[0]) {
      objData = {
        ...objData,
        post5: userPostData[4],
        like5: userPostData[4] && userPostData[4].like_count ? userPostData[4].like_count : '',
        comment5: userPostData[4] && userPostData[4].comments_count ? userPostData[4].comments_count : '',
      };
    }
    resolve(objData);
  });
}

const calculateAvg = (arr, count) => {
  let sum = 0;
  let avg = 0;
  arr.forEach((val) => {
    if (val) {
      sum += Number(val);
    }
  });
  avg = sum / count;
  return Math.round(avg);
};

const calFollowersSc = (followersCount, iterativeFollowers, score) => {
  // make recursive function
  // base case -> if followersCount < iterative followers (num + 5000)
  // recursive call -> if followersCount > iterative followers
  // parameters in recursive call -> followersCount, iterative followers, score

  if (followersCount <= iterativeFollowers) {
    return score;
  }

  let newScore = score;
  let scoreToAdd = 0;
  if (iterativeFollowers < 20000) {
    scoreToAdd = 100;
  } else if (iterativeFollowers >= 20000 && iterativeFollowers < 25000) {
    scoreToAdd = 150;
  } else if (iterativeFollowers >= 25000) {
    scoreToAdd = 200;
  }
  newScore += scoreToAdd;
  return calFollowersSc(followersCount, iterativeFollowers + 5000, newScore);
};

export function _verifyEmail(email) {
  let regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
  return regex.test(email);
}
