// MUI icons
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};

const navConfig = [
  // DASHBOARD GENERAL
  // ----------------------------------------------------------------------
  {
    type: 'client',
    subheader: 'campaigns',
    items: [
      { title: 'All campaigns', path: PATH_DASHBOARD.client.campaign, icon: <DashboardIcon /> },
      { title: 'Start a new campaign', path: PATH_DASHBOARD.client.startCampaign, icon: <AddIcon /> },
      { title: 'My account', path: PATH_DASHBOARD.client.client_account, icon: <AccountCircleIcon /> },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      //  { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    type: 'admin',
    subheader: 'management',
    items: [
      { title: 'Campaigns follow-up', path: PATH_DASHBOARD.admin.dashboard, icon: <DashboardIcon /> },
      { title: 'Influencer', path: PATH_DASHBOARD.admin.influencer, icon: <AccessibilityNewIcon /> },
      { title: 'Keyword', path: PATH_DASHBOARD.admin.keyword, icon: <FormatListBulletedIcon /> },
      { title: 'Admin', path: PATH_DASHBOARD.admin.admin, icon: <SupervisorAccountIcon /> },
      { title: 'My account', path: PATH_DASHBOARD.admin.admin_account, icon: <AccountCircleIcon /> },
      // { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
      /*
      {
        title: 'mail',
        path: PATH_DASHBOARD.mail.root,
        icon: ICONS.mail,
        info: (
          <Label variant="outlined" color="error">
            +32
          </Label>
        ),
      },
      */
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },

  // GENERAL
  // ----------------------------------------------------------------------
  {
    type: '',
    subheader: 'general',
    items: [
      { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },
  {
    type: 'influencer',
    subheader: 'General',
    items: [
      { title: 'Assigned campaigns', path: PATH_DASHBOARD.influencer.dashboard, icon: ICONS.dashboard },
      { title: 'My account', path: PATH_DASHBOARD.influencer.inf_account, icon: <AccountCircleIcon /> },
    ],
  },
];

export default navConfig;
