import PropTypes from 'prop-types';
import { useState } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Typography, Autocomplete, InputAdornment, Popper } from '@mui/material';
// hooks
import useIsMountedRef from '../../../../../../hooks/useIsMountedRef';
// components
import Image from '../../../../../../components/Image';
import Iconify from '../../../../../../components/Iconify';
import InputStyle from '../../../../../../components/InputStyle';
import SearchNotFound from '../../../../../../components/SearchNotFound';
import { useDispatch } from 'react-redux';
import { setFilterInfluencers } from '../../../../../../redux/slices/InfluencerFiltersSlice';

// ----------------------------------------------------------------------

const PopperStyle = styled((props) => <Popper placement="bottom-start" {...props} />)({
  width: '280px !important',
});

// ----------------------------------------------------------------------

InfluencerSearch.propTypes = {
  influencers: PropTypes.array,
  filters: PropTypes.object,
};

// ----------------------------------------------------------------------

export default function InfluencerSearch({ influencers, filters }) {
  const dispatch = useDispatch();

  const isMountedRef = useIsMountedRef();

  const [searchQuery, setSearchQuery] = useState('');

  const [searchResults, setSearchResults] = useState([]);

  const handleChangeSearch = async (value) => {
    try {
      if (isMountedRef.current) {
        let searchArr = [];
        let s_val = value?.toLowerCase();
        influencers.forEach((element) => {
          let s_ig_nme = element?.data?.info?.name?.toLowerCase();

          if (s_ig_nme && s_ig_nme.includes(s_val)) {
            searchArr.push(element);
          }
        });
        setSearchQuery(value);
        setSearchResults(searchArr);

        if (value === '') {
          dispatch(setFilterInfluencers({ ...filters, search: '' }));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = (name) => {
    dispatch(setFilterInfluencers({ ...filters, search: name }));
  };

  const handleKeyUp = () => {
    handleClick(searchQuery);
  };

  return (
    <Autocomplete
      size="small"
      autoHighlight
      popupIcon={null}
      PopperComponent={PopperStyle}
      options={searchResults}
      onInputChange={(event, value) => handleChangeSearch(value)}
      getOptionLabel={(influencer) => influencer?.data?.info?.name}
      noOptionsText={<SearchNotFound searchQuery={searchQuery} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <InputStyle
          {...params}
          stretchStart={250}
          placeholder="Search by name/IG username..."
          onKeyUp={handleKeyUp}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, influencer, { inputValue }) => {
        const { data } = influencer;
        const matches = match(data?.info?.name, inputValue);
        const parts = parse(data?.info?.name, matches);

        return (
          <li {...props}>
            <Image
              alt={data?.info?.name}
              src={data?.info?.profilePicUrl}
              sx={{ width: 48, height: 48, borderRadius: 1, flexShrink: 0, mr: 1.5 }}
            />
            <Link underline="none" onClick={() => handleClick(data?.info?.name)}>
              {parts.map((part, index) => (
                <Typography
                  key={index}
                  component="span"
                  variant="subtitle2"
                  color={part.highlight ? 'primary' : 'textPrimary'}
                >
                  {part.text}
                </Typography>
              ))}
            </Link>
          </li>
        );
      }}
    />
  );
}
