import { createSlice } from '@reduxjs/toolkit';

export const registerInfSlice = createSlice({
  name: 'registerInf',
  initialState: {
    infToken: '',
    pages: [],
    selectedPageId: '',
    selectedPageName: '',
    infData: null,
    loggedInFbId: '',
    loggedInFbToken: '',
    loggedInFbPageId: '',
    loggedInFbBusAccId: '',
  },
  reducers: {
    setInfToken: (state, action) => {
      state.infToken = action.payload;
    },
    setPages: (state, action) => {
      state.pages = action.payload;
    },
    setSelectedPageId: (state, action) => {
      state.selectedPageId = action.payload;
    },
    setSelectedPageName: (state, action) => {
      state.selectedPageName = action.payload;
    },
    setInfData: (state, action) => {
      state.infData = action.payload;
    },
    setLoggedInFbId: (state, action) => {
      state.loggedInFbId = action.payload;
    },
    setLoggedInFbToken: (state, action) => {
      state.loggedInFbToken = action.payload;
    },
    setLoggedInFbPageId: (state, action) => {
      state.loggedInFbPageId = action.payload;
    },
    setLoggedInFbBusAccId: (state, action) => {
      state.loggedInFbBusAccId = action.payload;
    },
    resetRegister: (state) => {
      state.infToken = '';
      state.pages = [];
      state.selectedPageId = '';
      state.selectedPageName = '';
      state.infData = null;
      state.loggedInFbId = '';
      state.loggedInFbToken = '';
      state.loggedInFbPageId = '';
      state.loggedInFbBusAccId = '';
    },
  },
});

export const {
  setInfToken,
  setPages,
  setSelectedPageId,
  setSelectedPageName,
  setInfData,
  setLoggedInFbId,
  setLoggedInFbToken,
  setLoggedInFbPageId,
  setLoggedInFbBusAccId,
  resetRegister,
} = registerInfSlice.actions;

export const selectRegisterInf = (state) => state.registerInf;

export default registerInfSlice.reducer;
