import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
//
import Logo from './Logo';
import ProgressBar from './ProgressBar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
  opacity: '0.5',
}));

export default function LoadingScreenNormal() {
  return (
    <>
      <ProgressBar />
      <RootStyle>
        <m.div initial={{ rotateY: 0 }}>
          <Logo disabledLink sx={{ width: 200, height: 25 }} />
          <br />
          <div align="center">
            <Typography>Loading...</Typography>
          </div>
        </m.div>
      </RootStyle>
    </>
  );
}
